import {
	useProductsSoldForEventOverview,
	useProductsSoldOverview,
} from "#resources/hooks/integrations/products/query";
import { useSalesReport } from "#pages/place/bar-reports/sales-report/context";

export const useProductsSoldOverviewByLevel = () => {
	const {
		selectedTab,
		groupBy,
		placeId,
		since,
		until,
		eventLevel,
		eventId,
		productsFilter,
		transactionsFilter,
		categoriesFilter,
	} = useSalesReport();

	const filters = {
		productIds: productsFilter.length ? productsFilter : null,
		sources: transactionsFilter.length ? transactionsFilter : null,
		categoryIds: categoriesFilter.length ? categoriesFilter : null,
	};

	const enabledPlaceRequests = Boolean(
		selectedTab === "total-sales" && groupBy === "no-group" && !eventLevel,
	);
	const enabledEventRequests = Boolean(
		selectedTab === "total-sales" && groupBy === "no-group" && eventLevel,
	);

	const {
		data: productsSoldOverviewData,
		isFetching: productsSoldOverviewIsFetching,
	} = useProductsSoldOverview(
		{
			since,
			until,
			placeId,
			filters,
		},
		{
			enabled: enabledPlaceRequests,
			retry: false,
			placeholderData: data => data,
		},
	);

	const {
		data: productsSoldForEventOverviewData,
		isFetching: productsSoldForEventOverviewIsFetching,
	} = useProductsSoldForEventOverview(
		{
			eventId: eventId || "",
			filters,
		},
		{
			enabled: enabledEventRequests,
			retry: false,
			placeholderData: data => data,
		},
	);

	const data = eventLevel ? productsSoldForEventOverviewData : productsSoldOverviewData;
	const isFetching = eventLevel
		? productsSoldForEventOverviewIsFetching
		: productsSoldOverviewIsFetching;

	return {
		data,
		isFetching,
	};
};
