export const vendor = {
	definitions: "Definitions",
	serviceFee: "Service Fee",
	serviceTransfer: "Service Transfer",
	serviceFeeVendor: "Service Fee Vendor",
	serviceFeeVendorRequired: "Select the service fee vendor",
	serviceFeeVendorPlaceholder: "Select the vendor to receive the transfer",
	paymentMethodSuccess: "Vendor payment methods were successfully updated!",
	serviceTransferSuccess: "Service fee vendor successfully updated!",
	saveMethods: "Save retentions",
	resetChanges: "Restore initial values",
	vendor: "Vendor",
	selectVendor: "Select a vendor",
	paymentMethods: "Retention of all amounts transacted in the place",
	tooltip:
		"If a vendor is selected, the entire amount transacted in the chosen payment method will be retained in the statement of that vendor.",
	paymentMethod: "Payment Method",
	paymentMethodOptions: {
		credit: "Credit not integrated",
		debit: "Debit not integrated",
		cash: "Cash",
		voucher: "Voucher",
	},
	reprocessButton: "Reprocess vendors",
	confirmReprocess: "Confirm reprocessing",
	reprocessLabel: "Event",
	reprocessPlaceholder: "Enter event ID",
	reprocessSuccess: "Vendors reprocessed successfully",
};
