export const productMenu = {
	manageMenu: "Gestão de cardápios",
	manageBars: "Bares",
	manageProducts: "Gestão de produtos",
	newMenu: "Novo cardápio",
	createNewMenu:
		"Crie, edite e gerencie cardápios com status, ativando ou inativando conforme a demanda.",
	requiredField: "Campo Obrigatório",
	menuName: "Nome do cardápio duplicado",
	duplicateMenu: "Duplicar Cardápio",
	closeAction: "Fechar",
	addProducts: "Adicionar Produtos",
	newMenuName: "Nome do Cardápio",
	duplicateMenuError:
		"Já existe um cardápio deste tipo ativo. Só pode existir um cardápio ativo por tipo.",
	menus: "Cardápios",
	status: "Status",
	type: "Tipo",
	createFirstMenu: "Crie seu primeiro cardápio agora",
	createMenuInstructions:
		"Crie agora o seu primeiro cardápio e vincule produtos e preços.",
	createMenu: "Criar Cardápio",
	duplicate: "Duplicar",
	delete: "Excluir",
	edit: "Editar",
	deleteMenu: "Excluir Cardápio",
	confirmDelete:
		"Deseja excluir o {{menuName}}? Todas as informações serão excluídas e essa ação não poderá ser desfeita.",
	active: "Ativo",
	confirmDuplicateMenu:
		"Deseja duplicar {{menuName}}? Todas as informações da réplica serão idênticas ao original, mas não estarão vinculadas a nenhuma área.",
	menuType: {
		normal: "Normal",
		delivery: "Delivery",
		dineIn: "Pedido na mesa",
		hawker: "Ambulante",
		grabAndGo: "Grab and Go",
		onlineFicha: "Ficha Digital",
		pickUp: "Retirada",
		deliveryByMarketplace: "Entrega pelo restaurante",
		onlineTab: "Comanda online",
		viewMenu: "Cardápio digital",
	},
	importProducts: "A partir de importação de produtos",
	importWithSheet: "Utilize planilha para importar novos produtos automaticamente",
	linkExistingProducts: "Vincular produtos existentes",
	selectImportedProducts:
		"Selecione produtos já importados para vinculá-los ao novo cardápio",
	importCategoriesProducts:
		"Serão importados e criados categorias e produtos. Caso o produto já exista, ele será duplicado nessa importação.",
	menuTypeLabel: "Tipo de Cardápio",
	downloadSampleSheet: "Clique aqui para baixar a planilha de exemplo",
	clickToAddSheet: "Clique para adicionar planilha",
	importSheet: "Importar Planilha",
	importProductsTitle: "Importar Produtos",
	emptyRowsWarning: "Sua planilha contém linhas vazias",
	linkProductsToMenu: "Vincular produtos ao cardápio",
	noProductFound: "Nenhum produto encontrado",
	enterNewMenuName: "Digite o nome do novo cardápio",
	selectMenuType: "Selecione o tipo do cardápio",
	addProductsCount: "Adicionar {{products}} produtos",
	selectProductsToLink: "Selecione os produtos que deseja vincular ao novo cardápio",
	loadingProducts: "Carregando produtos",
	fileLoadError:
		"Não conseguimos carregar o seu arquivo. Por favor, confirme se o arquivo foi salvo corretamente e tente novamente",
	loadingSheet: "Carregando planilha",
	invalidRowsError: "Linhas inválidas, verifique as seguintes colunas: {{rows}}",
	linkProductToMenu: "Vincular produto ao cardápio",
	createProduct: "Criar Produto",
	confirmRemoveProdutFromMenu:
		"Deseja desvincular {{productName}} do cardápio? Caso existam informações editadas de preço, você as perderá. Se quiser apenas desativar o produto do cardápio, você pode clicar na chave do status na tabela dos produtos do cardápio.",
	removeProductFromMenu: "Desvincular produto do cardápio",
	confirmRemoveProductFromMenuLabel: "Desvincular produto",
	revertProductPriceTitle: "Desfazer alteração de preço",
	confirmRevertProductPriceDescription:
		"Ao confirmar essa ação, você voltará ao preço do produto, descartando a alteração feita previamente. O preço do produto são os preços definidos na seção 'Gestão de Produto'.",
	editProductTitle: "Editar {{productName}}",
	category: "Categoria",
	price: "Preço",
	saveChanges: "Salvar Alterações",
	editMenuProduct: "Editar produto no cardápio",
	yes: "Sim",
	no: "Não",
	viewProductPage: "Ver Página do Produto",
	product: "Produto",
	mountable: "Montável",
	searchProduct: "Buscar Produto",
	importWithSheetToMenu:
		"Utilize planilha para importar novos produtos automaticamente para o cardápio",
	createProductFromScratch: "Criar produto do zero",
	createNewProduct: "Crie um novo produto preenchendo todos os dados necessários",
	selectProductsToLinkToMenu: "Selecione os produtos que deseja vincular ao cardápio",
	addProductsToMenu: "Adicione produtos ao cardápio",
	emptyMenu: "Cardápio vazio",
	addProductsToStartUsingIt: "Adicione produtos para começar a usar",
	sku: "SKU",
	nameMaxLength: "Limite de {{maxLength}} caracteres excedido",
	menuCreated: "Cardápio criado com sucesso",
	menuUpdated: "Cardápio editado com sucesso",
	menuRemoved: "Cardápio removido com sucesso",
	menuDuplicated: "Cardápio duplicado",
	menuStatusChanged: "Status do cardápio alterado",
	menuProductPriceChanged:
		"O preço do {{productName}} foi alterado com sucesso no {{menuName}}",
	productsAmountAtMenu: "qtd. de produtos",
	products: "produto(s)",
};
