export const onboarding = {
	modularization: {
		title: "Introducing <strong>New Zig Menu</strong>",
		content:
			"<strong>Now you can...</strong> view your financial history in a detailed and personalized way, with advanced filters and reports that make it easier to track your transactions.",
		primaryActionLabel: "Learn more",
		secondaryActionLabel: "Skip",
	},
	bonusReport: {
		title: "Track Awarded and Used Bonuses",
		content:
			"Now you can track awarded and used bonuses through detailed reports with total distributed, customer name, employee, reason, and amount. Check out the new report by accessing the Bonus tab in your location’s financial reports.",
		primaryActionLabel: "Close",
	},
	salesReport: {
		title: "It is now called Sales Reports!",
		content:
			"The old Bar Report still exists, but it is now called Sales Reports. To access it, go to the location menu and click on the 'Sales Reports' tab.",
		primaryActionLabel: "Close",
	},
};
