import { soldProducts } from "./sold-products";
import { salesReport } from "./sales-report";
import { chipsBurned } from "./chips-burned";
import { waiterReport } from "./waiter-report";
import { deliveredProductsByBar } from "./delivered-products-by-bar";
import { soldBaseProductReport } from "./sold-base-product-report";
import { soldRankingReport } from "./sold-ranking-report";

export const barReports = {
	printDate: "Emitido dia",
	reportPeriod: "Período do relatório",
	filter: "Filtrar por tipo de transação",
	filterPlaceholder: "Selecione um tipo de transação",
	filterOptions: {
		all: "Todos",
		balcony: "Balcão",
		cashless: "Cashless",
		dash: "Dashboard",
		delivery: "Delivery",
		ficha: "Ficha",
		grabngo: "Grab n Go",
		onlineBill: "Comanda Online",
		zigQrcode: "QR Code Zig",
	},
	soldProducts,
	salesReport,
	chipsBurned,
	waiterReport,
	deliveredProductsByBar,
	soldBaseProductReport,
	soldRankingReport,
	tipReport: {
		title: "Relatório de Gorjeta",
		filename: "relatório-de-gorjeta_{{date}}",
		subtitle:
			"Relação de valores a serem recebidos pelos operadores/garçons referente as gorjetas de serviço.",
		filterByPeriod: "Filtrar por período",
		filterByEmployee: "Filtrar por funcionário",
		filterByEmployeePlaceholder: "Selecione o(s) funcionário(s)",
		eventPeriod: "Período do evento",
		statusRunning: "(em andamento)",
		columns: {
			name: "Nome",
			total: "Total em gorjetas",
			cashTotal: "Dinheiro",
			debitTotal: "Débito",
			creditTotal: "Crédito",
			appTotal: "App",
			pixTotal: "Pix",
			bonusTotal: "Bônus",
		},
		alert: {
			title: "Atenção",
			message:
				"O total em gorjetas inclui contas em aberto, o que pode gerar diferenças nos valores totais das formas de pagamento. As formas de pagamento serão atualizadas após o fechamento da conta em aberto.",
		},
	},
	yes: "Sim",
	no: "No",
	noCard: "Sem cartão",
	grams: "gramas",
	kilos: "Kilos",
	liter: "Litros",
	by: "por",
	productReturnsReport: {
		title: "Devolução de produto",
		product: "Produto",
		quantity: "QTD",
		returnValue: "Valor da devolução",
		totalValue: "Total",
		noRecords: "Sem registros",
		noReturnedProducts: "Não houveram devoluções neste período",
		noReturnsThisEvent: "Não houveram devoluções neste evento",
		productReturnReport: "Relatório de devolução de produtos",
		totalReturnedProducts: "Total de produtos devolvidos",
		productsReturnedByEmployee: "Produtos devolvidos por funcionário",
		employee: "Funcionário",
		summary: "Resumo",
	},
};
