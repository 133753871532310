import React from "react";
import { TableProductsSold } from "#pages/place/bar-reports/sales-report/components";
import { useTranslation } from "react-i18next";
import { sumField } from "#pages/place/bar-reports/sales-report/helpers/calc";
import { LoadingOutlined } from "@ant-design/icons";
import { TableTotalPagination } from "#components/table-components/table-total-pagination";
import { TableEmptyState } from "#components/table-empty-state";
import { useProductsSoldByBarReportByLevel } from "#pages/place/bar-reports/sales-report/hooks";

import s from "./table-products-sold-by-bar.module.scss";

export const TableProductsSoldByBar = () => {
	const {
		data: dataSourceProductsByBar,
		isLoading: isLoadingProductsSoldByBar,
	} = useProductsSoldByBarReportByLevel();

	const { t } = useTranslation("place", {
		keyPrefix: "barReports.salesReport.totalSales.tableProductsSold",
	});
	const { t: tGK } = useTranslation("globalKeys");

	return (
		<div className={s.barTables}>
			{isLoadingProductsSoldByBar && <LoadingOutlined />}

			{!isLoadingProductsSoldByBar && dataSourceProductsByBar.length === 0 && (
				<TableEmptyState emptyType="noData" description={<></>} />
			)}

			{dataSourceProductsByBar.map(bar => {
				const { products, barName: _barName } = bar;

				const countAmount = sumField(products, "count");
				const subtotalAmount = sumField(products, "subtotal");
				const discountAmount = sumField(products, "discount");
				const totalAmount = sumField(products, "totalValue");
				const barName = _barName || t("noBar");

				return (
					<div className={s.tableBarWrapper} key={bar.barId}>
						<strong>{barName}</strong>

						<TableProductsSold
							dataSource={products}
							TableProps={{
								pagination: {
									showTotal: (total, range) => (
										<TableTotalPagination total={total} range={range} />
									),
									showSizeChanger: true,
									defaultPageSize: 5,
								},
							}}
							totalizers={{
								totalDescription: _barName
									? t("totalInBar", {
											barName,
									  })
									: tGK("total"),
								countAmount,
								subtotalAmount,
								discountAmount,
								totalAmount,
							}}
						/>
					</div>
				);
			})}
		</div>
	);
};
