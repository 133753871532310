import { PurchaseInvoiceTranslations } from "./purchase-invoice-pt-br";

export const PURCHASE_INVOICE_FR_FR: PurchaseInvoiceTranslations = {
	importationInvoices: {
		cards: {
			importXml: {
				title: "Importation via XML",
				description: "Importez vos factures fiscales via des fichiers XML",
				button: "Cliquez pour importer",
			},
			importManual: {
				title: "Importation manuelle",
				description: "Entrez vos factures fiscales manuellement",
				button: "Cliquez pour importer",
			},
			importSefaz: {
				title: "Importation via Sefaz",
				description: "Importez vos factures fiscales directement du Sefaz",
				button: "Cliquez pour importer",
			},
		},
		importXml: {
			input: {
				title: "Sélectionnez le fichier XML pour commencer l'importation",
				description:
					"Vous pouvez cliquer sur « Choisir un fichier » ou glisser un fichier",
				button: "Choisir un fichier",
			},
			labels: {
				storage: "Stock",
				selectStorage: "Sélectionnez un stock",
				xmlInvoice: "XML de la facture",
				changeXml: "Changer XML",
				back: "Retour",
				import: "Importer",
			},
			selectedFile: "Le fichier sélectionné est : ",
			xmlRequiredMessage: "Le fichier XML est obligatoire.",
			storageIdRequiredMessage: "Sélectionnez un stock.",
			uploadErrorMessage: "Impossible de lire le fichier.",
			modalTitle: "Importation de XML",
		},
		importManual: {
			modalTitle: "Importation manuelle",
		},
		fiscalProfileModalTitle: "Importation via Sefaz",
		fiscalProfileRequiredMessage: "Le profil fiscal est obligatoire.",
		labels: {
			fiscalProfile: "Profil Fiscal",
			selectFiscalProfile: "Sélectionnez un profil fiscal",
			back: "Retour",
			continue: "Continuer",
		},
		importSefazInvoice: {
			title: "Importation via Sefaz",
		},
	},
};
