import { useEffect, useState } from "react";
import { useSalesReport } from "#pages/place/bar-reports/sales-report/context";
import {
	Pagination,
	PaginationResponse,
	ProductsSoldOrderBy,
} from "#resources/api/enterprise-generated";
import { TOTAL_PER_PAGE } from "#pages/place/bar-reports/sales-report";
import {
	useProductsSoldReport,
	useProductsSoldReportForEvent,
} from "#resources/hooks/integrations/products/query";

export const useProductsSoldReportByLevel = () => {
	const {
		selectedTab,
		groupBy,
		placeId,
		since,
		until,
		eventLevel,
		eventId,
		productsFilter,
		transactionsFilter,
		categoriesFilter,
	} = useSalesReport();

	const [orderBy, setOrderBy] = useState<ProductsSoldOrderBy | null>({
		column: "totalValue",
		order: "desc",
	});

	const [pagination, setPagination] = useState<Pagination>({
		currentPage: 1,
		perPage: TOTAL_PER_PAGE,
	});

	const [paginationResponse, setPaginationResponse] = useState<PaginationResponse>({
		currentPage: 1,
		perPage: TOTAL_PER_PAGE,
		lastPage: 1,
		total: 0,
	});

	const filters = {
		productIds: productsFilter.length ? productsFilter : null,
		sources: transactionsFilter.length ? transactionsFilter : null,
		categoryIds: categoriesFilter.length ? categoriesFilter : null,
	};

	const enabledPlaceRequests = Boolean(
		selectedTab === "total-sales" && groupBy === "no-group" && !eventLevel,
	);
	const enabledEventRequests = Boolean(
		selectedTab === "total-sales" && groupBy === "no-group" && eventLevel,
	);

	const {
		data: productsSoldReportData,
		isFetching: productsSoldReportIsFetching,
	} = useProductsSoldReport(
		{
			placeId,
			since,
			until,
			filters,
			pagination,
			orderBy,
		},
		{
			enabled: enabledPlaceRequests,
			retry: false,
			placeholderData: data => data,
		},
	);

	const {
		data: productsSoldReportForEventData,
		isFetching: productsSoldReportForEventIsFetching,
	} = useProductsSoldReportForEvent(
		{
			eventId: eventId || "",
			filters,
			pagination,
			orderBy,
		},
		{
			enabled: enabledEventRequests,
			retry: false,
			placeholderData: data => data,
		},
	);

	const data = eventLevel ? productsSoldReportForEventData : productsSoldReportData;
	const isFetching = eventLevel
		? productsSoldReportForEventIsFetching
		: productsSoldReportIsFetching;

	useEffect(() => {
		if (!data?.pagination) return;

		setPaginationResponse(prev => ({
			...prev,
			total: data.pagination.total,
			perPage: data.pagination.perPage,
			lastPage: data.pagination.lastPage,
		}));
	}, [data?.pagination]);

	return {
		data,
		isFetching,
		setPagination,
		orderBy,
		pagination: {
			...paginationResponse,
			currentPage: pagination.currentPage,
		},
		setOrderBy,
	};
};
