export const formatter = {
	frequencyOfEarning: {
		eachEvent: "Uma vez por evento",
		everytime: "Sempre",
		oncePerMonth: "Uma vez por mês",
		oncePerWeek: "Uma vez por semana",
	},
	promotionEarning: {
		everybody: {
			name: "Todos os clientes",
			description: "Para promoções que não exigem nenhuma condição de uso",
		},
		minimumRechargeValue: {
			name: "Valor mínimo de recarga",
			description: "Para promoções que exigem uma recarga mínima do cliente.",
		},
		gender: {
			name: "Gênero",
			description: "Para promoções exclusivas para mulheres, homens ou outros.",
		},
		birthdayPerson: {
			name: "Aniversário",
			description: "Para promoções para aniversariantes do mês, semana ou dia.",
		},
		checkinTime: {
			name: "Horário de chegada",
			description: "Para promoções exigem um horário de chegada específico.",
		},
		minimumValueConsumed: {
			name: "Valor mínimo consumido",
			description:
				"Para promoções que exigem que o usuário consuma um valor mínimo para ter direito à promoção.",
		},
		ppuUsage: {
			name: "Uso de PPU",
			description: "Para promoções de vendas feitas exclusivamente pelo App.",
		},
		productsConsumed: {
			name: "Compra de produto específico",
			description:
				"Para promoções que o cliente tem direito a promoção ao comprar algum produto ou categoria de produtos.",
		},
		guestList: {
			name: "Lista de convidados",
		},
		maximumValueConsumed: {
			name: "Valor máximo consumido",
		},
		minimumPlaceFrequency: {
			name: "Frequência mínima do local",
		},
		maximumPlaceFrequency: {
			name: "Frequência máxima no local",
		},
		productsConsumedQuantity: {
			name: "Quantidade de produtos consumidos",
		},
	},
	promotionUsage: {
		period: {
			name: "Horário",
			description: "Defina uma faixa de horário que o cliente pode utilizar a promoção",
		},
		secondsToExpire: {
			name: "Expiração pós-ganho",
			description:
				"Defina quanto tempo o cliente tem para aproveitar a promoção que ele tem direito",
		},
		weekDays: {
			name: "Dias da semana",
			description: "Defina que dias da semana o cliente pode utilizar a promoção",
		},
	},
	convertUsageConditions: {
		empty: "Não possui",
		at: "até",
		days: "dias",
	},
	convertGoals: {
		empty: "Não possui",
		everybody: "Sua promoção não exige nenhuma meta",
		checkinTime: "Horário de chegada",
	},
};
