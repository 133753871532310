import * as React from "react";
import { observer } from "mobx-react-lite";

interface Props {
	params: {
		placeId: string;
	};
}

function DeliveryLayout({ children }: React.PropsWithChildren<Props>) {
	return <>{children}</>;
}

export default observer(DeliveryLayout);
