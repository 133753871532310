export const giftCard = {
	tabs: {
		campaigns: "Campanhas",
		metabase: "Desempenho",
	},
	pageTitleList: "Gift Card",
	pageSubtitle: "Configure seu Gift Card para campanhas.",
	modalTitle: "Gift Cards",
	modalDescription:
		"Campanha criada com sucesso. Agora você pode exportar o arquivo para resgatar os cupons.",
	manageGiftCardModal: {
		title: {
			active: "Ativar campanha",
			inactive: "Inativar campanha",
		},
		description: {
			active: "Ativando campanha ({{campaign}}), continuar?",
			inactive: "Inativando campanha ({{campaign}}), continuar?",
		},
	},
	fields: {
		campaignName: "Nome da campanha",
		campaignPlaces: "Local",
		campaignCreatedAt: "Data de criação",
		numberOfGifts: "Qtd de cupons",
		giftCardValue: "Valor unitário",
		giftCardExpirationDate: "Data de expiração",
		giftCardRedeemed: "Resgatado",
		validation: {
			campaignNameAlreadyExists: "Esse nome de campanha já existe",
		},
	},
	form: {
		validations: {
			required: "Campo obrigatório",
		},
		messages: {
			successInCreating: "Campanha criada com sucesso",
			failedToCreate: "Falha ao criar a campanha",
			successInCreatingSubtitle:
				"Tenha acesso a todos códigos da campanha baixando a planilha a baixo:",
			campaignEdited: "Campanha atualizada",
			campaignEditedFailure: "Falha ao atualizar a campanha",
		},
	},
	actions: {
		label: "Ações",
		exportCupons: "Exportar Cupons",
		track: "Acompanhamento",
		createCampaign: "Criar campanha",
		inactivate: "Inativar",
		activate: "Ativar",
	},
	status: {
		label: "Status",
		active: "Ativa",
		inactive: "Inativa",
		expired: "Expirado",
	},
	excel: {
		title: "Gift Cards",
		filename: "gift-cards",
		columns: {
			giftCardcodes: "Códigos",
			giftCardValue: "Valor unitário",
		},
	},
};
