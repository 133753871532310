export const giftback = {
	giftbacks: {
		title: "Giftbacks",
		form: {
			active: "Ativar giftback",
			name: "Nome do giftback",
			namePlaceHolder: "Digite o nome do giftback",
			minimumConsumptionLabel: "Valor mínimo de consumo",
			minimumConsumptionTooltip:
				"Valor que o cliente deve consumir para converter em giftback.",
			percentageLabel: "Porcentagem do giftback",
			percentageTooltip:
				"Quanto do valor consumido pelo cliente será presenteado ao mesmo.",
			expiresInLabel: "Expirar giftback em até",
			expiresInTooltip:
				"Quantidade máxima de dias em que o cliente deve utilizar o giftback.",
			expiresInPlaceholder:
				"Selecione a quantidade máxima de dias em que o cliente deve utilizar o giftback",
			expiresAtLabel: "Período do resgate",
			expiresAtTooltip: "Tempo que o cliente terá para aproveitar o giftback.",
			expiresAtPlaceholder: "Selecione o período de validade do giftback",
			placeLabel: "Locais",
			removeGiftback: "Remover giftback",
			selectAll: "Selecionar todos",
			day: "dia",
			days: "dias",
			week: "semana",
			noValidity: "Sem validade",
		},
		tabs: {
			addNewGiftback: "Adicionar novo giftback",
		},
	},
	giftbackList: {
		activate: "Ativar",
		deleteConfirm: "Tem certeza que deseja excluir giftback?",
		deleteGiftback: "Excluir giftback",
		table: {
			createdAt: "Criado em",
			expiresAt: "Encerrado em",
			expiresIn: "Período",
			giftback: "Giftback",
			messagesSent: "Sms enviados",
			places: "Locais",
			status: "Status",
		},
		title: "Listagem de giftbacks",
	},
	giftbackReport: {
		selectGiftbackLabel: "Campanha",
		selectGiftbackPlacholder: "Selecione a(s) campanha(s)",
		table: {
			createdAt: "Criado em",
			giftback: "Campanha",
			messagesSent: "Sms enviados",
			places: "Locais",
			rescues: "Resgates",
			totalValue: "{{ currencySymbol }} em vendas",
			giftbackSent: "Total de giftbacks",
			giftbackValue: "{{ currencySymbol }} giftback resgatado",
			conversionRate: "Taxa de conversão",
		},
		title: "Relatório de resgates",
		totalizers: {
			quantity: "Campanhas criadas",
			totalSms: "Total de SMS criados",
			totalRescues: "Total de resgates",
			totalSales: "Total em vendas utilizando giftback",
		},
	},
	giftbackPowerBi: {
		title: "Relatório de Giftback",
	},
};
