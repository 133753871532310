import classNames from "classnames";
import React from "react";
import Icon from "#components/icon";

import s from "./expand-button.module.scss";

export type ExpandButtonProps = {
	expanded: boolean;
	onExpand: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const ExpandButton = ({ expanded, onExpand }: ExpandButtonProps) => {
	return (
		<button
			className={classNames(s.collapseButton, {
				[s.expandedRowIcon]: expanded,
			})}
			onClick={onExpand}
		>
			<Icon width={24} height={24} icon={"chevron-down"} />
		</button>
	);
};
