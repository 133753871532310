import { merge } from "lodash-es";
import frJson from "./fr.json";
import { PURCHASE_INVOICE_FR_FR } from "#pages/place/purchase-invoice/translations/purchase-invoice-fr-fr";
import { IMPORT_FR_FR } from "#pages/place/purchase-invoice/import-invoice/translations/import-fr-fr";
import { CONCILIATION_FR_FR } from "#pages/backoffice/conciliation/translations/conciliation-fr-fr";
import { deliverySettings } from "./place/delivery/settings";

const fr = merge(frJson, {
	place: {
		purchaseInvoiceV2: PURCHASE_INVOICE_FR_FR,
		import: IMPORT_FR_FR,
		backoffice: { conciliation: CONCILIATION_FR_FR },
		delivery: {
			updateManyExternalIntegrations: {
				success: "Integrations mises jour avec succs",
			},
			deliverySettings,
		},
	},
});

export { fr };
