import React from "react";
import { Cell, Grid } from "#components/grid-structure";
import { Select } from "#components/primitive";

import { SelectTransactionSource } from "#components/select-transaction-source";
import { TransactionSource } from "#resources/api/enterprise-generated";
import { useSalesReport } from "#pages/place/bar-reports/sales-report/context";
import { GroupBy } from "#pages/place/bar-reports/sales-report/types";
import { useTranslation } from "react-i18next";
import {
	TableProductsSoldByBar,
	TableProductsSoldNoGroup,
} from "#pages/place/bar-reports/sales-report/components";
import { treatStringForSearch } from "#resources/helpers/string";

import s from "./table-total-sales.module.scss";

export const TableTotalSales = () => {
	const { t } = useTranslation("place", {
		keyPrefix: "barReports.salesReport.totalSales",
	});
	const {
		transactionsFilter,
		setTransactionsFilter,
		productsFilter,
		setProductsFilter,
		setBarsFilter,
		groupBy,
		setGroupBy,
		productsOptions,
		barOptions,
		categoriesOptions,
		categoriesFilter,
		setCategoriesFilter,
		barsFilter,
	} = useSalesReport();

	return (
		<div className={s.container}>
			<div className={s.filters}>
				<Grid className={s.grid} areas={[["group", "transaction", "product", "bar"]]}>
					<Cell area="group">
						<Select<GroupBy>
							size="large"
							defaultValue={groupBy}
							placeholder={t("groupPlaceholder")}
							onChange={val => setGroupBy(val)}
						>
							<Select.Option value="bar">{t("groupByBar")}</Select.Option>
							<Select.Option value="no-group">{t("noGroup")}</Select.Option>
						</Select>
					</Cell>

					<Cell area="transaction">
						<SelectTransactionSource
							defaultValue={transactionsFilter}
							onChange={(values: TransactionSource[]) => setTransactionsFilter(values)}
							placeholder={t("transactionPlaceholder")}
							maxTagCount="responsive"
						/>
					</Cell>

					<Cell area="product">
						<Select<string[], { label: string }>
							defaultValue={productsFilter}
							size="large"
							placeholder={t("productPlaceholder")}
							onChange={values => setProductsFilter(values)}
							mode="multiple"
							options={productsOptions}
							optionFilterProp="label"
							maxTagCount="responsive"
							filterOption={(inputValue, option) =>
								treatStringForSearch(option?.label ?? "").includes(
									treatStringForSearch(inputValue),
								)
							}
							allowClear
						/>
					</Cell>

					<Cell area="bar">
						{groupBy === "bar" ? (
							<Select<string[], { label: string }>
								key="bar"
								defaultValue={barsFilter}
								size="large"
								placeholder={t("barPlaceholder")}
								onChange={values => setBarsFilter(values)}
								mode="multiple"
								optionFilterProp="label"
								maxTagCount="responsive"
								options={barOptions}
								filterOption={(inputValue, option) =>
									treatStringForSearch(option?.label ?? "").includes(
										treatStringForSearch(inputValue),
									)
								}
								allowClear
							/>
						) : (
							<Select<string[], { label: string }>
								key="category"
								defaultValue={categoriesFilter}
								size="large"
								placeholder={"Filtrar por categoria"}
								onChange={values => setCategoriesFilter(values)}
								mode="multiple"
								optionFilterProp="label"
								maxTagCount="responsive"
								options={categoriesOptions}
								filterOption={(inputValue, option) =>
									treatStringForSearch(option?.label ?? "").includes(
										treatStringForSearch(inputValue),
									)
								}
								allowClear
							/>
						)}
					</Cell>
				</Grid>
			</div>

			{groupBy === "bar" && <TableProductsSoldByBar />}

			{groupBy === "no-group" && <TableProductsSoldNoGroup />}
		</div>
	);
};
