import { createQueryFactory } from "../query-factory";
import { PRODUCTS_KEYS } from "./keys";

export const { useApiHook: usePlaceProducts } = createQueryFactory(
	"getPlaceProducts",
	{},
	PRODUCTS_KEYS.getPlaceProducts,
);

export const { useApiHook: useAllProducts } = createQueryFactory("getAllProducts");

export const { useApiHook: usePlaceProductsByType } = createQueryFactory(
	"getPlaceProductsByType",
	{},
	PRODUCTS_KEYS.getPlaceProductsByType,
);

export const { useApiHook: useProductsSoldByBarReport } = createQueryFactory(
	"productsSoldByBarReport",
	{
		retry: false,
	},
);

export const { useApiHook: useProductMasterKinds } = createQueryFactory(
	"getProductMasterKinds",
);

export const { useApiHook: useProductsSoldByBarReportByEvent } = createQueryFactory(
	"productsSoldByBarReportByEvent",
	{
		retry: false,
	},
);

export const {
	useApiHook: useProductsSoldByBarReportByEventOverview,
} = createQueryFactory("productsSoldByBarReportByEventOverview", {
	retry: false,
});

export const { useApiHook: useProductsSoldReport } = createQueryFactory(
	"productsSoldReport",
	{
		retry: false,
	},
);

export const { useApiHook: useProductsSoldReportForEvent } = createQueryFactory(
	"productsSoldReportForEvent",
	{
		retry: false,
	},
);

export const { useApiHook: useProductsSoldOverview } = createQueryFactory(
	"productsSoldOverview",
	{
		retry: false,
	},
);

export const { useApiHook: useProductsSoldForEventOverview } = createQueryFactory(
	"productsSoldForEventOverview",
	{
		retry: false,
	},
);
