export const giftback = {
	giftbacks: {
		title: "Giftbacks",
		form: {
			active: "Activate giftback",
			name: "Giftback's name",
			namePlaceHolder: "Enter the giftback's name",
			minimumConsumptionLabel: "Minimum consumption value",
			minimumConsumptionTooltip:
				"Amount the customer must consume to convert into giftback.",
			percentageLabel: "Giftback percentage",
			percentageTooltip:
				"How much of the customer's consumed value will be gifted back to them.",
			expiresInLabel: "Expire giftback within",
			expiresInTooltip: "Maximum number of days the customer must use the giftback.",
			expiresInPlaceholder:
				"Select maximum number of days the customer must use the giftback",
			expiresAtLabel: "Redemption period",
			expiresAtTooltip: "Time the customer will have to enjoy the giftback.",
			expiresAtPlaceholder: "Select the giftback's validity period",
			placeLabel: "Locations",
			removeGiftback: "Remove giftback",
			selectAll: "Select all",
			day: "day",
			days: "days",
			week: "week",
			noValidity: "No validity",
		},
		tabs: {
			addNewGiftback: "Add new giftback",
		},
	},
	giftbackList: {
		activate: "Activate",
		deleteConfirm: "Are you sure you want to delete the giftback?",
		deleteGiftback: "Delete giftback",
		table: {
			createdAt: "Created at",
			expiresAt: "Expires at",
			expiresIn: "Period",
			giftback: "Giftback",
			messagesSent: "SMS sent",
			places: "Places",
			status: "Status",
		},
		title: "Giftbacks listing",
	},
	giftbackReport: {
		selectGiftbackLabel: "Campaign",
		selectGiftbackPlaceholder: "Select the campaign(s)",
		table: {
			createdAt: "Created at",
			giftback: "Campaign",
			messagesSent: "SMS sent",
			places: "Places",
			rescues: "Rescues",
			totalValue: "{{ currencySymbol }} value in sales",
			giftbackSent: "Total of giftbacks",
			giftbackValue: "{{ currencySymbol }} giftback redeemed",
			conversionRate: "Conversion rate",
		},
		title: "Rescue report",
		totalizers: {
			quantity: "Created campaigns",
			totalRescues: "Total rescues",
			totalSales: "Total sales using giftback",
			totalSms: "Total SMS created",
		},
	},
	giftbackPowerBi: {
		title: "Giftback Report",
	},
};
