export const promotionInfo = {
	title: "Dados da promoção",
	description:
		"Defina todos os parâmetros necessários para criar uma promoção especializada para os seus clientes.",
	promotionName: {
		label: "Nome da promoção",
		placeholder: "Digite o nome da promoção",
	},
	promotionDescription: {
		label: "Descrição da promoção",
		placeholder: "Digite a descrição da promoção",
	},
	expiration: {
		label: "Expiração",
		info:
			"A promoção será válida até a data selecionada. Quando chegar a data de expiração, a promoção ficará inativa e você poderá alterar a data de expiração.",
		notExpire: "Não expira",
	},
	automaticMessage: {
		label: "Mensagens automáticas para clientes",
		info:
			"Todos os clientes que ganharem a promoção recebem mensagens automáticas a partir do canal selecionado. Esses envios tem custo",
		sendMessage: "Enviar mensagens automáticas",
	},
	whatsUp: {
		label: "Exibir promoções no 'Qual é a boa'",
		info: "Todas as promoções habilitadas serão exibidas no 'Qual é a boa'.",
		showPromotions: "Exibir promoções no 'Qual é a boa'",
	},
	places: "Locais da promoção",
	errors: {
		requiredField: "Esse campo é obrigatório",
		dateFormat: "Formato de data inválido",
		dayDiff: "Necessário pelo menos um dia de diferença",
		selectPlace: "Selecione pelo menos um local",
		maxUses:
			"Valor não pode ser superior a quantidade de produtos definida no passo anterior",
	},
};
