export const vendor = {
	definitions: "Definições",
	serviceFee: "Taxa de serviço",
	serviceTransfer: "Transferência de serviço",
	serviceFeeVendor: "Vendor da taxa de serviço",
	serviceFeeVendorRequired: "Selecione o vendor da taxa de serviço",
	serviceFeeVendorPlaceholder: "Selecione o vendor que receberá a transferência",
	paymentMethodSuccess:
		"Os Métodos de pagamentos de Vendor foram atualizados com sucesso!",
	serviceTransferSuccess: "Vendor da taxa de serviço foi atualizado com sucesso!",
	saveMethods: "Salvar retenções",
	resetChanges: "Restaurar valores iniciais",
	vendor: "Vendor",
	selectVendor: "Selecione um vendor",
	paymentMethods: "Retenção de todos os valores transacionados no place",
	tooltip:
		"Caso algum vendor seja selecionado, todo o valor transacionado no método de pagamento escolhido será retido no extrato desse vendor.",
	paymentMethod: "Método de pagamento",
	paymentMethodOptions: {
		credit: "Crédito não integrado",
		debit: "Débito não integrado",
		cash: "Dinheiro",
		voucher: "Voucher",
	},
	reprocessButton: "Reprocessar vendors",
	confirmReprocess: "Confirmar reprocessamento",
	reprocessLabel: "Evento",
	reprocessPlaceholder: "Digite o ID do evento",
	reprocessEmptySelect: "Selecione pelo menos um evento para reprocessar",
	reprocessSuccess: "Vendors reprocessados com sucesso",
};
