import * as React from "react";
import { BroadcastChannelClass } from "#resources/helpers/broadcast-channel";

export function useCreateSupplierChannel() {
	const broadcastChannel = React.useRef(new BroadcastChannelClass("supplier-channel"));

	React.useEffect(() => {
		return () => {
			broadcastChannel.current.close();
		};
	}, []);

	return broadcastChannel.current;
}
