export const salesReport = {
	title: "Sales Report",
	tabTitle: "Sales",
	exportButton: "Export",
	exportLabelWithFilters: "Report with selected filters",
	exportAllSales: "Detailed Sales",
	exportOthersReportsLabel: "Other reports",
	exportTotalSales: "Products Sold",
	productsSoldLabel: "Quantity of products sold",
	subtotalLabel: "Subtotal",
	subtotalTooltip: "Unit value x Quantity sold",
	discountsLabel: "Discounts",
	totalProductsSoldLabel: "Total in products sold",
	totalProductsSoldTooltip: "(Unit value x Quantity sold) - Discounts",
	allSalesTab: "Detailed Sales",
	totalSalesTab: "Products Sold",
	paymentTypes: {
		bonus: "Bonus",
		fromApp: "Paid in App",
		fromEvent: "Paid at the event",
		fromOtherEvent: "Paid at another event",
		minimumConsumption: "Minimum consumption",
		post: "Post",
		ppu: "Pre",
	},
	allSales: {
		table: {
			columns: {
				date: "Date",
				client: "Client",
				document: "Document",
				employee: "Employee",
				products: "Products",
				paymentType: "Payment method",
				isRefunded: "Refunded",
				discount: "Discount",
				transactionValue: "Transaction value",
			},
			total: "Total",
			method: "method",
			methods: "methods",
			product: "product",
			products: "products",
		},
		filters: {
			clientPlaceholder: "Filter by client",
			employeePlaceholder: "Filter by employee",
			paymentTypePlaceholder: "Filter by payment",
		},
	},
	totalSales: {
		groupPlaceholder: "Group by product category",
		groupByBar: "Group by bar",
		groupByCategory: "Group by product category",
		noGroup: "No grouping",
		transactionPlaceholder: "Filter by transaction type",
		productPlaceholder: "Filter by product",
		barPlaceholder: "Filter by bar",
		tableProductsSold: {
			sku: "SKU",
			name: "Name",
			unitQuantity: "Unit Qty",
			unitValue: "Unit Value",
			subtotal: "Subtotal",
			discounts: "Discounts",
			totalValue: "Total Value",
			addCategory: "Add Category",
			totalInBar: "Total in {{barName}}",
			productImageAlt: "Product image",
			category: "Category",
			values: "{{length}} Values",
			mountable: "mountable",
			noBar: "No bar",
		},
		mountableProductsTable: {
			name: "Name",
			section: "Mountable Section",
			count: "Quantity",
		},
	},
};
