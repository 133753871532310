import React, { useMemo } from "react";
import { Cell, Grid } from "#components/grid-structure";
import { Input, Select, TagLabel } from "#components/primitive";
import { Popover, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { formatDate } from "#resources/helpers/date-format";
import { useCurrency } from "#resources/hooks";
import { singularOrPlural } from "#resources/helpers/string";

import { TableEmptyState } from "#components/table-empty-state";
import {
	useSalesOptions,
	useSalesReport,
} from "#pages/place/bar-reports/sales-report/context";
import {
	PaymentMethod,
	SalesDetails,
	SalesDetailsOrderBy,
	SalesDetailsOrderByColumn,
	SalesDetailsOrderByOrder,
	SalesDetailsProducts,
} from "#resources/api/enterprise-generated";
import { SorterResult, SortOrder } from "antd/lib/table/interface";

import { useTranslation } from "react-i18next";
import { parsePaymentMethodV2 } from "#resources/helpers/humanize";
import { SORTABLE_FIELDS } from "#pages/place/bar-reports/sales-report/context/constants";
import { ExpandButton } from "#components/table-components/expand-button";
import { TableTotalPagination } from "#components/table-components/table-total-pagination";
import {
	useGetAllPlaceSalesByLevel,
	useGetAllPlaceSalesSummaryByLevel,
} from "#pages/place/bar-reports/sales-report/hooks";

import s from "./table-all-sales.module.scss";

type SortableFields = typeof SORTABLE_FIELDS[number];

export const TableAllSales = () => {
	const { t } = useTranslation("place", { keyPrefix: "barReports.salesReport.allSales" });
	const { t: tProductsTable } = useTranslation("place", {
		keyPrefix: "barReports.salesReport.totalSales.tableProductsSold",
	});
	const { formatCurrency } = useCurrency();

	const {
		setClientNameFilter,
		clientNameFilter: _clientNameFilter,
		setEmployeesFilter,
		employeesFilter: _employeesFilter,
		paymentMethodsFilter: _paymentMethodsFilter,
		setPaymentMethodsFilter,
	} = useSalesReport();

	const { employeesOptions, paymentMethodsOptions } = useSalesOptions();

	const {
		data,
		isFetching: tableDataIsFetching,
		pagination,
		setPagination,
		orderBy,
		setOrderBy,
	} = useGetAllPlaceSalesByLevel();

	const {
		data: totalizersData,
		isFetching: totalizersIsFetching,
	} = useGetAllPlaceSalesSummaryByLevel({
		manageByFilters: true,
		manageBySelectedTab: true,
	});

	const isLoading = tableDataIsFetching || totalizersIsFetching;

	const discountsTotal = formatCurrency(totalizersData?.discount || 0);
	const transactionAmount = formatCurrency(totalizersData?.totalInSales || 0);

	const dataSource = data?.data || [];

	const sortOrderByColumn = useMemo(() => {
		const result: Record<SortableFields, SortOrder> = {
			date: null,
			isRefunded: null,
		};

		if (!orderBy) {
			return result;
		}

		SORTABLE_FIELDS.forEach(sortableField => {
			if (orderBy?.column === sortableField) {
				result[sortableField] = orderBy.order === "asc" ? "ascend" : "descend";
			}
		});

		return result;
	}, [orderBy]);

	const columns: ColumnsType<SalesDetails> = [
		{
			key: "date",
			dataIndex: "date",
			title: t("table.columns.date"),
			render: date => formatDate(date, "DATE_HOUR"),
			sorter: true,
			defaultSortOrder: sortOrderByColumn.date,
		},
		{ key: "name", dataIndex: "name", title: t("table.columns.client"), sorter: false },
		{
			key: "document",
			dataIndex: "document",
			title: t("table.columns.document"),
			sorter: false,
		},
		{
			key: "sellerName",
			dataIndex: "sellerName",
			title: t("table.columns.employee"),
			sorter: false,
		},
		{
			key: "products",
			dataIndex: "products",
			title: t("table.columns.products"),
			sorter: false,
			render: (products: SalesDetails["products"]) => {
				if (!products?.length) {
					return null;
				}

				const total = products.reduce((prev, curr) => curr.count + prev, 0);

				return (
					<>
						{total.toString().padStart(2, "0")}{" "}
						{singularOrPlural(
							Array.from({ length: total }),
							t("table.product"),
							t("table.products"),
						)}
					</>
				);
			},
		},
		{
			key: "paymentMethods",
			dataIndex: "paymentMethods",
			title: t("table.columns.paymentType"),
			sorter: false,
			render: (paymentMethods: PaymentMethod[] = []) => {
				if (!paymentMethods?.length) return null;

				if (paymentMethods?.length === 1) return parsePaymentMethodV2(paymentMethods[0]);

				return (
					<Popover
						content={<>{paymentMethods?.map(parsePaymentMethodV2).join(", ")}</>}
						placement="right"
						showArrow={false}
						overlayInnerStyle={{
							borderRadius: "var(--border-radius-sm)",
						}}
					>
						{paymentMethods?.length.toString().padStart(2, "0")}{" "}
						{singularOrPlural(
							paymentMethods || [],
							t("table.method"),
							t("table.methods"),
						)}
					</Popover>
				);
			},
		},
		{
			key: "isRefunded",
			dataIndex: "isRefunded",
			title: t("table.columns.isRefunded"),
			sorter: true,
			defaultSortOrder: sortOrderByColumn.isRefunded,
			render: (isRefunded: boolean) =>
				isRefunded ? (
					<TagLabel type="error">{t("table.columns.isRefunded")}</TagLabel>
				) : null,
		},
		{
			key: "discount",
			dataIndex: "discount",
			title: t("table.columns.discount"),
			sorter: false,
			render: value => (value ? formatCurrency(value) : "-"),
			align: "right",
		},
		{
			key: "value",
			dataIndex: "value",
			title: t("table.columns.transactionValue"),
			sorter: false,
			render: (value, r) =>
				value ? (
					<>
						{r.isRefunded ? (
							<del>{formatCurrency(value)}</del>
						) : (
							<>{formatCurrency(value)}</>
						)}
					</>
				) : (
					"-"
				),
			align: "right",
		},
	];

	const handleTableChange = (
		_sorter: SorterResult<SalesDetails> | SorterResult<SalesDetails>[],
	) => {
		const sorter = Array.isArray(_sorter) ? _sorter[0] : _sorter;

		if (!sorter?.columnKey || !sorter.order) {
			setOrderBy(null);
			return;
		}

		const { columnKey, order } = sorter;

		const sortOrderApi: SalesDetailsOrderByOrder = order === "ascend" ? "asc" : "desc";

		const result: SalesDetailsOrderBy = {
			column: columnKey as SalesDetailsOrderByColumn,
			order: sortOrderApi,
		};

		setOrderBy(result);
	};

	return (
		<div className={s.container}>
			<div className={s.filters}>
				<Grid className={s.grid} areas={[["client", "employee", "payment"]]}>
					<Cell area="client">
						<Input
							size="large"
							defaultValue={_clientNameFilter}
							onChange={e => setClientNameFilter(e.target.value)}
							placeholder={t("filters.clientPlaceholder")}
						/>
					</Cell>

					<Cell area="employee">
						<Select<string[]>
							defaultValue={_employeesFilter}
							size="large"
							mode="multiple"
							placeholder={t("filters.employeePlaceholder")}
							onChange={values => setEmployeesFilter(values)}
							optionFilterProp="children"
							maxTagCount="responsive"
							allowClear
						>
							{employeesOptions.map(({ label, value }) => (
								<Select.Option key={value} value={value}>
									{label}
								</Select.Option>
							))}
						</Select>
					</Cell>

					<Cell area="payment">
						<Select<PaymentMethod[]>
							defaultValue={_paymentMethodsFilter}
							size="large"
							placeholder={t("filters.paymentTypePlaceholder")}
							onChange={values => setPaymentMethodsFilter(values)}
							mode="multiple"
							maxTagCount="responsive"
							allowClear
						>
							{paymentMethodsOptions.map(({ label, value }) => (
								<Select.Option key={value} value={value}>
									{label}
								</Select.Option>
							))}
						</Select>
					</Cell>
				</Grid>
			</div>

			<div>
				<Table
					rowKey={record =>
						`${record.transactionId}-${record.date?.getDate()}-${record.value}-${
							record.sellerId
						}-${record.userId}-${record.document}`
					}
					columns={columns}
					loading={isLoading}
					dataSource={dataSource}
					onChange={(_pagination, _filters, sorter) => handleTableChange(sorter)}
					pagination={{
						current: pagination.currentPage,
						total: pagination.total,
						pageSize: pagination.perPage,
						showTotal: (total, range) => (
							<TableTotalPagination total={total} range={range} />
						),
						showSizeChanger: false,
						onChange: (pageNumber, pageSize) =>
							setPagination(prev => ({
								...prev,
								currentPage: pageNumber,
								perPage: pageSize,
							})),
					}}
					locale={{
						emptyText: <TableEmptyState emptyType="noFilters" description={<></>} />,
					}}
					expandable={{
						expandIcon: ({ expanded, onExpand, record, expandable }) =>
							expandable ? (
								<ExpandButton expanded={expanded} onExpand={e => onExpand(record, e)} />
							) : null,
						expandIconColumnIndex: 9,
						columnWidth: 53,
						expandedRowRender: record => {
							const columns: ColumnsType<SalesDetailsProducts> = [
								{
									key: "name",
									dataIndex: "name",
									title: tProductsTable("name"),
								},
								{
									key: "count",
									dataIndex: "count",
									title: tProductsTable("unitQuantity"),
									align: "right",
								},
								{
									key: "discount",
									dataIndex: "discount",
									title: tProductsTable("discounts"),
									align: "right",
									render: value => formatCurrency(value),
								},
								{
									key: "total",
									dataIndex: "total",
									title: tProductsTable("totalValue"),
									align: "right",
									render: value => formatCurrency(value),
								},
							];

							return (
								<Table
									columns={columns}
									dataSource={record.products || []}
									pagination={false}
									size="small"
								/>
							);
						},
					}}
					summary={() => (
						<Table.Summary fixed={"top"}>
							<Table.Summary.Row
								style={{ backgroundColor: "var(--color-success-lightest)" }}
							>
								<Table.Summary.Cell index={0} colSpan={7}>
									<b>{t("table.total")}</b>
								</Table.Summary.Cell>
								<Table.Summary.Cell index={1} colSpan={1} align="right">
									<b>{discountsTotal}</b>
								</Table.Summary.Cell>
								<Table.Summary.Cell index={2} colSpan={1} align="right">
									<b>{transactionAmount}</b>
								</Table.Summary.Cell>
								<Table.Summary.Cell index={3} colSpan={1} />
							</Table.Summary.Row>
						</Table.Summary>
					)}
					sticky
				/>
			</div>
		</div>
	);
};
