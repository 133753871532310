import { createMutationFactory } from "../mutation-factory";

export const {
	useApiHook: useExportAllTransactionProductsXlsxPlace,
} = createMutationFactory("exportAllTransactionProductsXlsxPlace");

export const { useApiHook: useExportAllPlaceOrEventActions } = createMutationFactory(
	"exportAllPlaceOrEventActions",
);

export const { useApiHook: useProductsSoldXlsx } = createMutationFactory(
	"productsSoldXlsx",
);

export const { useApiHook: useProductsSoldByBarXlsx } = createMutationFactory(
	"productsSoldByBarXlsx",
);
