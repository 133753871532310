import * as React from "react";
import { BroadcastChannelClass } from "#resources/helpers/broadcast-channel";

export const createProductBroadcastChannel = new BroadcastChannelClass("product-channel");

export function useCreateProductChannel() {
	const broadcastChannel = React.useRef(createProductBroadcastChannel);

	React.useEffect(() => {
		return () => {
			broadcastChannel.current.close();
		};
	}, []);

	return broadcastChannel.current;
}
