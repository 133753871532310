export const manualInvoiceImporting = {
	form: {
		num: "Invoice number",
		serie: "Invoice series",
		date: "Issue date",
		inputDate: "Physical stock entry date",
		operationType: "Operation type",
		accessKey: "Access key",
		supplier: "Issuer",
		suppliers: "Suppliers",
		newSupplier: "New supplier",
		totalValue: "Invoice value",
		discountValue: "Discounts",
		finalValue: "Total value",
		amountOfDuplicates: "Number of installments",
		firstDueDate: "First due date",
		duplicateInterval: "Interval between installments",
		duplicatesDetails: "Installment details",
		duplicateNumber: "Installment number",
		dueDate: "Due date",
		value: "Value",
		intervalTypes: {
			Month: "Monthly",
			Week: "Weekly",
		},
		shippingValue: "Shipping",
		insuranceValue: "Insurance",
		otherExpensesValue: "Additional expenses",
		icmsValue: "ICMS",
		icmsstValue: "ICMS ST",
		ipiValue: "IPI",
		associatedProduct: "Associated product",
		associatedChartAccount: "Chart of accounts",
		count: "Quantity",
		unitCostValue: "Unit cost",
		totalCost: "Total cost",
		basicStorageUnit: "Unit of measurement",
		success: "Invoice imported successfully",
		storage: "Storage",
		accessKeyInfo:
			// eslint-disable-next-line max-len
			"The NF-e access key consists of 44 numeric digits that identify the electronic invoice. It includes information such as the issuer's tax ID (CNPJ), state code, year and month of issuance, invoice number, model, series, and a unique control code.",
	},
	sections: {
		inviceDetails: "Invoice details",
		duplicates: "Installment payment",
		expenses: "Shipping and expenses",
		taxes: "Taxes",
		products: "Products",
	},
	validations: {
		storageIsRequired: "It is mandatory to select a storage.",
		duplicateInvoice: "This invoice has already been imported.",
		supplierIsRequired: "It is mandatory to select an issuer.",
		numIsRequired: "It is mandatory to provide the invoice number.",
		serieIsRequired: "It is mandatory to provide the invoice series.",
		operationTypeIsRequired: "It is mandatory to provide the operation type.",
		totalValueIsRequired: "It is mandatory to provide the total invoice value.",
		productsIsRequired: "It is mandatory to add at least one product.",
		invalidAccessKey: "Invalid access key.",
	},
};
