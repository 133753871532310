export const formatter = {
	frequencyOfEarning: {
		eachEvent: "Once per event",
		everytime: "Always",
		oncePerMonth: "Once a month",
		oncePerWeek: "Once a week",
	},
	promotionEarning: {
		everybody: {
			name: "All customers",
			description: "For promotions that do not require any conditions of use",
		},
		minimumRechargeValue: {
			name: "Minimum recharge amount",
			description: "For promotions that require a minimum customer top-up.",
		},
		gender: {
			name: "Gender",
			description: "For exclusive promotions for women, men or others.",
		},
		birthdayPerson: {
			name: "Birthday",
			description: "For specials for birthdays of the month, week or day.",
		},
		checkinTime: {
			name: "Arrival time",
			description: "For promotions require a specific arrival time.",
		},
		minimumValueConsumed: {
			name: "Minimum amount consumed",
			description:
				"For promotions that require the user to consume a minimum amount to be eligible for the promotion.",
		},
		ppuUsage: {
			name: "PPU Usage",
			description: "For sales promotions made exclusively through the App.",
		},
		productsConsumed: {
			name: "Purchase a specific product",
			description:
				"For promotions that the customer is entitled to promotion when buying a product or category of products.",
		},
		guestList: {
			name: "Guest List",
		},
		maximumValueConsumed: {
			name: "Maximum amount consumed",
		},
		minimumPlaceFrequency: {
			name: "Minimum location frequency",
		},
		maximumPlaceFrequency: {
			name: "Maximum frequency on site",
		},
		productsConsumedQuantity: {
			name: "Number of products consumed",
		},
	},
	promotionUsage: {
		period: {
			name: "Time",
			description: "Define a time range that the customer can use the promotion",
		},
		secondsToExpire: {
			name: "Post-gain expiration",
			description:
				"Define how much time the customer has to take advantage of the promotion he is entitled to",
		},
		weekDays: {
			name: "Days of the week",
			description: "Define which days of the week the customer can use the promotion",
		},
	},
	convertUsageConditions: {
		empty: "Does not have",
		at: "until",
		days: "days",
	},
	convertGoals: {
		empty: "Does not have",
		everybody: "Your promotion doesn't require any goals",
		checkinTime: "Arrival Time",
	},
};
