const MODULARIZATION_KEY = "O Dash Zig Evoluiu!";
const BONUS_REPORT_KEY = "Relatório de bônus";
const SALES_REPORT_KEY = "Relatório de Vendas!";

export const ONBOARDING_KEYS = {
	modularization: MODULARIZATION_KEY,
	bonusReport: BONUS_REPORT_KEY,
	salesReport: SALES_REPORT_KEY,
} as const;

export type OnboardingKeysType = keyof typeof ONBOARDING_KEYS;
