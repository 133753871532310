import React from "react";
import {
	ProductsSoldOrderByOrder,
	ProductsSoldReportResponse,
} from "#resources/api/enterprise-generated";
import { TableTotalPagination } from "#components/table-components/table-total-pagination";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/lib/table";
import { useCurrency } from "#resources/hooks";
import { TagLabel } from "#components/primitive";
import {
	MountableProductsModal,
	TableProductsSold,
	useMountableProductsModal,
} from "#pages/place/bar-reports/sales-report/components";
import { SorterResult } from "antd/lib/table/interface";
import {
	useProductsSoldOverviewByLevel,
	useProductsSoldReportByLevel,
} from "#pages/place/bar-reports/sales-report/hooks";
import { numberSorter } from "#components/table-components/data-table/sorters";
import { formatProportionalValue } from "#pages/place/bar-reports/sales-report/helpers/format";

import s from "./table-products-sold-no-group.module.scss";

export const TableProductsSoldNoGroup = () => {
	const { t } = useTranslation("place", {
		keyPrefix: "barReports.salesReport.totalSales.tableProductsSold",
	});
	const { t: tGK } = useTranslation("globalKeys");

	const { formatCurrency } = useCurrency();

	const {
		handleOpenMountableProductsModal,
		...mountableProductsModalProps
	} = useMountableProductsModal();

	const {
		data,
		isFetching: productsSoldReportIsFetching,
		setOrderBy,
		orderBy,
		setPagination,
		pagination,
	} = useProductsSoldReportByLevel();

	const {
		data: overviewData,
		isFetching: overviewIsLoading,
	} = useProductsSoldOverviewByLevel();

	const isLoading = productsSoldReportIsFetching || overviewIsLoading;

	const totalizers = {
		countAmount: overviewData?.count || 0,
		discountAmount: overviewData?.discount || 0,
		subtotalAmount: overviewData?.subtotal || 0,
		totalAmount: overviewData?.totalValue || 0,
	};

	const columns: ColumnsType<ProductsSoldReportResponse> = [
		{
			key: "fiscalCode",
			dataIndex: "fiscalCode",
			title: t("sku"),
		},
		{
			key: "image",
			dataIndex: "image",
			title: "",
			width: 80,
			render: (value: ProductsSoldReportResponse["image"]) => {
				if (!value?.url) {
					return <div className={s.productImage} />;
				}

				return (
					<img src={value.url} alt={t("productImageAlt")} className={s.productImage} />
				);
			},
		},
		{
			key: "productName",
			dataIndex: "productName",
			title: t("name"),
			sorter: false,
			render: (name, product) => {
				return (
					<>
						{name}

						{product.mountableProducts && (
							<u role="button" onClick={() => handleOpenMountableProductsModal(product)}>
								({t("mountable")})
							</u>
						)}
					</>
				);
			},
		},
		{
			key: "count",
			dataIndex: "count",
			title: t("unitQuantity"),
			align: "right",
			sorter: (a, b) => numberSorter(a.count, b.count),
		},
		{
			key: "unitValue",
			dataIndex: "unitValue",
			title: t("unitValue"),
			align: "right",
			render: (unitValue: number[], r) => {
				if (
					(r.productValues && r.productValues?.length > 1) ||
					(r.proportionalValue && r.proportionalValue.length > 1)
				) {
					return (
						<TagLabel className={s.infoTag} type="info">
							{t("values", {
								length: r.productValues?.length,
							})}{" "}
						</TagLabel>
					);
				}

				if (r.proportionalValue && r.proportionalValue.length === 1) {
					return formatProportionalValue(r.proportionalValue[0]);
				}

				return formatCurrency(unitValue[0] || 0);
			},
		},
		{
			key: "subtotal",
			dataIndex: "subtotal",
			title: t("subtotal"),
			align: "right",
			render: value => formatCurrency(value),
		},
		{
			key: "discount",
			dataIndex: "discount",
			title: t("discounts"),
			align: "right",
			render: value => formatCurrency(value),
		},
		{
			key: "totalValue",
			dataIndex: "totalValue",
			title: t("totalValue"),
			align: "right",
			render: value => formatCurrency(value),
			sorter: (a, b) => numberSorter(a.totalValue, b.totalValue),
			defaultSortOrder: orderBy?.order === "asc" ? "ascend" : "descend",
		},
	];

	const handleTableChange = (
		_sorter:
			| SorterResult<ProductsSoldReportResponse>
			| SorterResult<ProductsSoldReportResponse>[],
	) => {
		const sorter = Array.isArray(_sorter) ? _sorter[0] : _sorter;

		if (!sorter?.columnKey || !sorter.order) {
			setOrderBy(null);
			return;
		}

		const { order } = sorter;

		const sortOrderApi: ProductsSoldOrderByOrder = order === "ascend" ? "asc" : "desc";

		setOrderBy({
			column: "productName",
			order: sortOrderApi,
		});
	};

	const dataSource = data?.productList || [];

	return (
		<>
			<MountableProductsModal {...mountableProductsModalProps} />

			<TableProductsSold
				dataSource={dataSource}
				TableProps={{
					loading: isLoading,
					columns,
					onChange: (_pagination, _filters, sorter) => handleTableChange(sorter),
					pagination: {
						current: pagination.currentPage,
						total: pagination.total,
						pageSize: pagination.perPage,
						showTotal: (total, range) => (
							<TableTotalPagination total={total} range={range} />
						),
						showSizeChanger: true,
						onChange: (pageNumber, pageSize) =>
							setPagination(prev => ({
								...prev,
								currentPage: pageNumber,
								perPage: pageSize,
							})),
					},
				}}
				totalizers={{
					...totalizers,
					totalDescription: tGK("total"),
				}}
			/>
		</>
	);
};
