import { useSalesReport } from "#pages/place/bar-reports/sales-report/context";
import {
	useProductsSoldByBarReport,
	useProductsSoldByBarReportByEvent,
} from "#resources/hooks/integrations/products/query";

export const useProductsSoldByBarReportByLevel = () => {
	const {
		placeId,
		since,
		until,
		selectedTab,
		groupBy,
		barsFilter,
		transactionsFilter,
		productsFilter,
		eventId,
		eventLevel,
	} = useSalesReport();

	const isSelectedTabAndGroupByBar = selectedTab === "total-sales" && groupBy === "bar";

	const enabledRequestPlaceLevel = isSelectedTabAndGroupByBar && !eventLevel;
	const enabledRequestEventLevel = isSelectedTabAndGroupByBar && eventLevel;

	const filters = {
		barIds: barsFilter.length ? barsFilter : null,
		productIds: productsFilter.length ? productsFilter : null,
		sources: transactionsFilter.length ? transactionsFilter : null,
	};

	const {
		data: productsSoldByBarReportData = [],
		isLoading: productsSoldByBarReportIsLoading,
	} = useProductsSoldByBarReport(
		{
			placeId,
			since,
			until,
			filters,
		},
		{
			enabled: enabledRequestPlaceLevel,
			retry: false,
		},
	);

	const {
		data: productsSoldByBarReportByEventData = [],
		isLoading: productsSoldByBarReportByEventIsLoading,
	} = useProductsSoldByBarReportByEvent(
		{
			eventId: eventId || "",
			filters,
		},
		{
			enabled: enabledRequestEventLevel,
			retry: false,
		},
	);

	const data = eventLevel
		? productsSoldByBarReportByEventData
		: productsSoldByBarReportData;
	const isLoading = eventLevel
		? productsSoldByBarReportByEventIsLoading
		: productsSoldByBarReportIsLoading;

	return { data, isLoading };
};
