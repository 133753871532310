export const extract = {
	title: "Extrato de vendor",
	placeAvaliable: "seu saldo disponível",
	vendorOwned: "devido aos vendors",
	anticipationBalance: "Seu saldo disponível para antecipação",
	toBeAnticipatedToVendors: "Disponível para antecipação de vendors",
	period: "Período",
	requiredField: "Campo obrigatório",
	tabs: {
		withdraws: {
			title: "Solicitações financeiras",
			export: "Exportar para Excel",
			columns: {
				vendorName: "Vendor",
				totalAvailable: "Saldo",
				type: {
					label: "Tipo",
					Withdraw: "Saque",
					Anticipation: "Antecipação",
				},
				totalRequestedValue: "Valor",
			},
			sheet: {
				title: "Extrato de vendor",
				filename: "extrato-vendor",
				columns: {
					vendorName: "Vendor",
					requestDate: "Data",
					availableValue: "Saldo",
					withdrawValue: "Solicitação de saque",
					anticipationValue: "Solicitação de antecipação",
				},
			},
		},
		history: {
			title: "Histórico",
			export: "Exportar para Excel",
			columns: {
				requestDate: "Data",
				vendorName: "Vendor",
				status: "Status",
				paymentMethod: "Método de pagamento",
				attachments: "Anexos",
				attachment: "Anexo",
				withdrawValue: "Valor",
				anticipationValue: "Valor de antecipação",
				proRataFee: "Taxa de pro rata",
				type: "Tipo",
			},
			labels: {
				paid: "Pago",
				processing: "Processando",
				pending: "Pendente",
				refused: "Recusado",
			},
			sheet: {
				title: "Extrato de vendor",
				filename: "extrato-vendor",
				columns: {
					requestDate: "Data",
					vendorName: "Vendor",
					status: "Status",
					paymentMethod: "Método de pagamento",
					withdrawValue: "Valor",
				},
			},
		},
		adjustments: {
			title: "Ajustes",
			export: "Exportar para Excel",
			add: "Adicionar Ajuste",
			deleteAdjustmentSuccess: "Ajuste Excluído",
			sheet: {
				title: "Extrato de vendor",
				filename: "extrato-vendor",
				columns: {
					originalPayDate: "Data de competência",
					date: "Data de liquidação",
					vendorName: "Nome do vendor",
					title: "Nome",
					obs: "Observação",
					value: "Valor",
				},
			},
			columns: {
				originalPayDate: "Data de competência",
				date: "Data de liquidação",
				vendorName: "Nome do vendor",
				title: "Nome",
				obs: "Observação",
				value: "Valor",
				category: "Categoria",
			},
			import: {
				vendorId: "Id do vendor",
				title: "Nome do ajuste",
				value: "Valor (em centavos)",
				originalPayDate: "Data de competência",
				date: "Data de liquidação",
				type: "Tipo de ajuste",
				category: "Categoria",
				obs: "Observação",
				importButton: "Importar ajustes",
				vendorName: "Nome do vendor",
				successNotification: "Ajustes importados com sucesso.",
				vendors: "Vendors",
				electrical: "Elétrica",
				hydraulics: "Hidráulica",
				supply: "Provisão",
				cleaning: "Limpeza",
				other: "Outros",
				vendorIdTextError: "Erro: Digite o Id do Vendor",
				vendorIdIsValidError: "Erro:  Digite um Id do Vendor válido",
				titleTextError: "Erro:  Digite um nome ao Ajuste",
				titleIsValidError: "Erro:  Nome de ajuste com mais de 255 caracteres",
				valueZeroError: "Erro:  Digite um valor de ajuste diferente de 0",
				valueCentsError:
					"Atenção! O valor é informado em centavos, ex: 123 equivale a R$ 1,23",
				categoryError: "Erro:  Digite uma categoria de ajuste válida",
				obsError: "Erro:  Obs de ajuste com mais de 255 caracteres",
			},
			total: "Total",
			modal: {
				deleteAdjustments: "Apagar ajustes",
				wantToDelete: "Deseja apagar ajustes?",
				cantBeUndone: "Essa ação não pode ser desfeita",
				cancel: "Cancelar",
			},
		},
	},
	adjustments: {
		add: "Adicionar novo ajuste",
		vendor: {
			label: "Vendor pra lançar ajuste",
			placeholder: "Selecione vendor para lançar os ajustes",
		},
		title: {
			label: "Nome do ajuste",
			placeholder: "Insira o nome do ajuste",
		},
		value: "Valor",
		date: "Data de liquidação",
		originalPayDate: "Data de competência",
		type: {
			label: "Tipo de ajuste",
			vendorShouldPay: "A pagar ao vendor",
			vendorShouldReceive: "A receber do vendor",
		},
		obs: "Observação",
		electrical: "Elétrica",
		hydraulics: "Hidráulica",
		cleaning: "Limpeza",
		supply: "Provisão",
		other: "Outros",
		category: "Categoria",
		categoryPlaceholder: "Selecione uma categoria",
	},
	withdraw: {
		title: "Solicitações de saque de {{name}}",
		requests: {
			title: "Requisições",
			label: "Deseja confirmar as seguintes solicitações de saque e antecipação?",
			reject: "Rejeitar selecionadas",
			approve: "Aprovar selecionadas",
			requestDate: "Solicitado em",
			availableValue: "saldo",
			anticipationValue: "Valor de Antecipação",
			prorata: "Valor de prorata",
			totalValue: "Valor Total:",
			type: {
				label: "Tipo",
				withdraw: "Saque",
				anticipation: "Antecipação",
			},
		},
		transference: {
			title: "Transferência",
			warning: "ATENÇÃO",
			message:
				"Solicitações realizadas após às 10h30 (horário de Brasília) só serão atendidas no dia seguinte.",
			typeMethod: {
				label: "Transferir com",
				zigAvailable: "Utilizar saldo Zig Pay",
				other: "Outro",
			},
			paymentMethod: {
				label: "Modo de pagamento",
				placeholder: "Informe o modo de pagamento",
			},
			attachments: "Anexos/Comprovantes",
			totalValue: "Valor do pagamento",
		},
	},
	pendingWithdraws: "Saques pendentes",
	pendingAnticipations: "Antecipações pendentes",
};
