export const deliverySettings = {
	title: "Configurar Delivery e Integrações",
	description: "Integre com um Delivery parceiro e centralize sua gestão na Zig.",
	integrateButton: "Nova integração",
	tabs: {
		management: "Gestão de integrações",
		integrate: "Integrar delivery",
	},
	management: {
		newMerchantTitle: "Adicionar nova loja",
		newMerchantButton: "Adicionar loja",
		labelInput: "ID da loja",
		integrationDescription: "Integração via API",
		integrationDescriptionWithDate: "Integração via API - {{date}}",
		removeIntegration: "Remover integração",
		saveChanges: "Salvar alterações",
		merchants: "Lojas",
		active: "Ativo",
		awaitIntegration: "Aguardando integração",
		removeIntegrationModal: {
			button: "Remover integração",
			title: "Remover integração",
			description: "Tem certeza que deseja remover as integrações?",
			okText: "Confirmar",
			cancelText: "Cancelar",
		},
		externalStoreIntegrationStatus: {
			synced: "Sincronizado",
			pendingSyncing: "Sincronização pendente",
			pendingImporting: "Importação pendente",
		},
		emptyState: {
			title: "Ainda não tem nada por aqui",
			description: "Parece que você ainda não tem um delivery integrado.",
			button: "Integrar um Delivery",
		},
	},
	integrate: {
		steps: {
			systemLabel: "Selecionar sistema de integração",
			authLabel: "Autenticar integração",
			syncMenuLabel: "Sincronizar cardápio",
			systemAndAuth: {
				titleSystemIntegration: "Sistema de integração",
				titleAuth: "Autenticação no sistema parceiro",
				descriptionIFood: "Cole aqui sua Chave de API disponibilizada pelo IFood.",
				descriptionAuthRappi: "Cole aqui sua Chave de API disponibilizada pelo Rappi.",
				labelSelect: "Selecione com quem vamos integrar",
				labelInput: "ID da loja",
				placeholderInput: "Cole aqui o código",
				submitButton: "Seguir com integração",
				buttonAddMore: "Adicionar mais uma loja",
				avisoTitle: "Precisamos de autorização para integrar com a Rappi",
				avisoDescription:
					"Envie uma solicitação para nosso atendimento que entraremos em contato para seguir com o processo de integração.",
				iFoodDialog: {
					title: "Precisamos de autorização para integrar",
					description:
						"Para seguirmos com a integração com o iFood, é necessário sua confirmação de solicitação. Para isso, você deve acessar o sistema e gerar uma Chave de API. Quando copiar a Chave do iFood, retorne para seguir com a integração.",
					cancelButton: "Cancelar integração",
					openButton: "Abrir iFood",
				},
			},
			syncMenu: {
				title: "Em breve você poderá importar seu cardápio!",
				descriptionIFood:
					"Em algumas semanas você poderá importar seu cardápio do iFood e usá-lo também no nosso sistema.",
				descriptionRappi:
					"Em algumas semanas você poderá importar seu cardápio do Rappi e usá-lo também no nosso sistema.",
				syncOffMenuButton: "Concluir integração sem cardápio",
			},
		},
	},
};
