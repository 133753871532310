import React from "react";
import { TagLabel } from "#components/primitive";
import {
	ProductsSoldReportResponse,
	ProductValuesHistory,
} from "#resources/api/enterprise-generated";
import { useCurrency } from "#resources/hooks";
import { Table } from "antd";
import { ColumnsType, TableProps } from "antd/lib/table";
import { TableEmptyState } from "#components/table-empty-state";
import { useTranslation } from "react-i18next";
import {
	MountableProductsModal,
	useMountableProductsModal,
} from "#pages/place/bar-reports/sales-report/components/mountable-products-modal";
import {
	numberSorter,
	stringSorter,
} from "#components/table-components/data-table/sorters";
import { formatProportionalValue } from "#pages/place/bar-reports/sales-report/helpers/format";
import { ExpandButton } from "#components/table-components/expand-button";

import s from "./table-products-sold.module.scss";

type TableProductsSoldProps = {
	dataSource: ProductsSoldReportResponse[];
	totalizers: {
		totalDescription: string;
		countAmount: number;
		subtotalAmount: number;
		discountAmount: number;
		totalAmount: number;
	};
	TableProps?: Partial<TableProps<ProductsSoldReportResponse>>;
};

export const TableProductsSold = ({
	dataSource,
	totalizers,
	TableProps,
}: TableProductsSoldProps) => {
	const { t } = useTranslation("place", {
		keyPrefix: "barReports.salesReport.totalSales.tableProductsSold",
	});
	const { formatCurrency } = useCurrency();

	const {
		handleOpenMountableProductsModal,
		...mountableProductsModalProps
	} = useMountableProductsModal();

	const columns: ColumnsType<ProductsSoldReportResponse> = [
		{
			key: "fiscalCode",
			dataIndex: "fiscalCode",
			title: t("sku"),
		},
		{
			key: "image",
			dataIndex: "image",
			title: "",
			width: 80,
			render: (value: ProductsSoldReportResponse["image"]) => {
				if (!value?.url) {
					return <div className={s.productImage} />;
				}

				return (
					<img src={value.url} alt={t("productImageAlt")} className={s.productImage} />
				);
			},
		},
		{
			key: "productName",
			dataIndex: "productName",
			title: t("name"),
			sorter: (a, b) => stringSorter(a.productName, b.productName),
			render: (name, product) => {
				return (
					<>
						{name}

						{product.mountableProducts && (
							<u role="button" onClick={() => handleOpenMountableProductsModal(product)}>
								({t("mountable")})
							</u>
						)}
					</>
				);
			},
		},
		{
			key: "count",
			dataIndex: "count",
			title: t("unitQuantity"),
			sorter: (a, b) => numberSorter(a.count, b.count),
			align: "right",
		},
		{
			key: "unitValue",
			dataIndex: "unitValue",
			title: t("unitValue"),
			align: "right",
			render: (unitValue: number[], r) => {
				if (
					(r.productValues && r.productValues?.length > 1) ||
					(r.proportionalValue && r.proportionalValue.length > 1)
				) {
					return (
						<TagLabel className={s.tagInfo} type="info">
							{t("values", {
								length: r.productValues?.length,
							})}{" "}
						</TagLabel>
					);
				}

				if (r.proportionalValue && r.proportionalValue.length === 1) {
					return formatProportionalValue(r.proportionalValue[0]);
				}

				return formatCurrency(unitValue[0] || 0);
			},
		},
		{
			key: "subtotal",
			dataIndex: "subtotal",
			title: t("subtotal"),
			sorter: (a, b) => numberSorter(a.subtotal, b.subtotal),
			align: "right",
			render: value => formatCurrency(value),
		},
		{
			key: "discount",
			dataIndex: "discount",
			title: t("discounts"),
			sorter: (a, b) => numberSorter(a.discount, b.discount),
			align: "right",
			render: value => formatCurrency(value),
		},
		{
			key: "totalValue",
			dataIndex: "totalValue",
			title: t("totalValue"),
			align: "right",
			sorter: (a, b) => numberSorter(a.totalValue, b.totalValue),
			defaultSortOrder: "descend",
			render: value => formatCurrency(value),
		},
	];

	const {
		countAmount,
		discountAmount,
		subtotalAmount,
		totalAmount,
		totalDescription,
	} = totalizers;

	return (
		<div className={s.container}>
			<MountableProductsModal {...mountableProductsModalProps} />

			<Table
				rowKey={row =>
					`${row.fiscalCode}-${row.productId}-${row.totalValue}-${row.categoryId}`
				}
				columns={columns}
				dataSource={dataSource}
				className={s.table}
				scroll={{
					x: "100%",
				}}
				expandable={{
					expandIcon: ({ expanded, onExpand, record, expandable }) =>
						expandable ? (
							<ExpandButton expanded={expanded} onExpand={e => onExpand(record, e)} />
						) : null,
					expandIconColumnIndex: 9,
					columnWidth: 53,
					rowExpandable: record =>
						Boolean(record.productValues && record.productValues?.length > 1),
					expandedRowRender: record => {
						const columns: ColumnsType<ProductValuesHistory> = [
							{
								key: "productName",
								dataIndex: "productName",
								title: t("name"),
								render: () => record.productName,
							},
							{
								key: "category",
								dataIndex: "category",
								title: t("category"),
								render: () => record.categoryName,
							},
							{
								key: "count",
								dataIndex: "count",
								title: t("unitQuantity"),
								align: "right",
							},
							{
								key: "unitValue",
								dataIndex: "unitValue",
								title: t("unitValue"),
								align: "right",
								render: (unitValue: number, r) => {
									if (r.proportionalValue) {
										return formatProportionalValue(r.proportionalValue);
									}

									return formatCurrency(unitValue);
								},
							},
							{
								key: "subtotal",
								dataIndex: "subtotal",
								title: t("subtotal"),
								align: "right",
								render: value => formatCurrency(value),
							},
							{
								key: "discount",
								dataIndex: "discount",
								title: t("discounts"),
								align: "right",
								render: value => formatCurrency(value),
							},
							{
								key: "totalValue",
								dataIndex: "totalValue",
								title: t("totalValue"),
								align: "right",
								render: value => formatCurrency(value),
							},
						];

						return (
							<Table
								columns={columns}
								dataSource={record.productValues || []}
								pagination={false}
								size="small"
							/>
						);
					},
				}}
				locale={{
					emptyText: <TableEmptyState emptyType="noFilters" description={<></>} />,
				}}
				summary={() => (
					<Table.Summary fixed={"top"}>
						<Table.Summary.Row
							style={{ backgroundColor: "var(--color-success-lightest)" }}
						>
							<Table.Summary.Cell index={0} colSpan={3}>
								<b>{totalDescription}</b>
							</Table.Summary.Cell>
							<Table.Summary.Cell index={1} colSpan={1} align="right">
								<b>{countAmount}</b>
							</Table.Summary.Cell>
							<Table.Summary.Cell index={2} colSpan={2} align="right">
								<b>{formatCurrency(subtotalAmount)}</b>
							</Table.Summary.Cell>
							<Table.Summary.Cell index={3} colSpan={1} align="right">
								<b>{formatCurrency(discountAmount)}</b>
							</Table.Summary.Cell>
							<Table.Summary.Cell index={4} colSpan={1} align="right">
								<b>{formatCurrency(totalAmount)}</b>
							</Table.Summary.Cell>
							<Table.Summary.Cell index={5} colSpan={1} />
						</Table.Summary.Row>
					</Table.Summary>
				)}
				sticky
				{...TableProps}
			/>
		</div>
	);
};
