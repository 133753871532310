export const kardex = {
	title: "Kardex Card",
	label: "Stock",
	storage: "Stock",
	product: "Product",
	seeProduct: "See product",
	applyFilters: "Apply filters",
	period: "Period",
	at: "until",
	destiny: "Destiny",
	origin: "Origin",
	dateFilterBy: {
		label: "Filter period by",
		options: {
			events: "Events between interval",
			interval: "All between interval",
		},
	},
	transferTypeTranslate: {
		input: "Input",
		transferOut: "Transfer (output)",
		transferIn: "Transfer (input)",
		lost: "Loss",
		sell: "Sell",
		refund: "Loss from chargeback",
		manual: "Manual adjustment",
		productionIn: "Production Entry",
		productionOut: "Production output",
		productionOutTo: "Production output for",
		invoice: "Invoice entry",
		inventory: "Inventory",
		productionLost: "Production Loss",
		cardExchange: "Exchange for card",
	},
	header: {
		date: "Date",
		employeeName: "Employee",
		note: "Note",
		initial: "Initial balance",
		noValue: "Value not informed",
		type: "Transaction",
		count: "Quantity",
		ending: "Final balance",
		unitCostValue: "Unit cost.",
		selectStock: "Select the stock",
		selectProduct: "Select the product",
		applyFilters: "Apply filters",
		description:
			"The kardex form is a representation of the stock and movements of an item. It is similar to the movement screen, showing the history of entries and exits, the quantity moved, the balance and values for each movement.",
	},
	nameNotFound: "Name not found",
	download: "Export to Excel",
	emptyState: {
		title: "Select stock to get started",
		description: "Select the stock, product and period to view the product's Kardex File",
	},
};
