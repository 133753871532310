import { action, observable } from "mobx";
import { RootStore } from ".";
import { ClientModel, ExtendedClient, IGetClientQuery } from "#models/client";
import { fromPromise, IPromiseBasedObservable } from "#helpers/mobx-utils";
import { ReportModel } from "#models/report";
import * as ReactGA from "react-ga";
import {
	showErrorNotification,
	showMessageOnTop,
	showSuccessNotification,
} from "#helpers/notifications";
import { errorTap } from "#helpers/errors";
import { fetchModel } from "#helpers/fetch-model";
import { treatStringForSearch } from "#helpers/string";
import { uniqBy } from "lodash-es";
import {
	BillPayment,
	Client,
	ClientBonus,
	ClientDetails,
	ClientDiscount,
	ClientOpenedBill,
	ClientTransferTransactionDetails,
	ConsolidatedHistory,
	ConsumptionObligationReport,
	PaymentMethod as EnterprisePaymentMethod,
	EventUser,
	MinimumConsumptionReport,
	MultipleUserIdsResult,
	NotEmmitedTransaction,
	OptionsClientsEvent,
	PixCharge,
	PostPaymentWithPastBill,
	PromotionUser,
	User,
	UserBonusReport,
	UserDetails,
	UserDetailsByEvents,
	UserEventHistory,
	UserRefundTransaction,
	UserTransaction,
} from "#resources/api/enterprise-generated";
import enterprise from "#resources/api/enterprise-client";
import i18n from "#i18n/index";
import { datetime, startOf, subtract } from "#resources/helpers/date-format";

interface IClientTransactionData {
	clientId: string;
	eventId: string;
}
interface IFieldFormattedUserDetails {
	userId: string;
	cpf: string;
	name: string;
}

interface Users {
	users: string[];
}
interface EventId {
	eventId: string;
}

interface PaymentMethod {
	paymentMethod: EnterprisePaymentMethod;
}
interface IsBOnus {
	isBonus: boolean;
}

type PayBillsArgs = Users & EventId & PaymentMethod & IsBOnus;

export type IFieldFormattedTransaction = IFieldFormattedUserDetails &
	NotEmmitedTransaction;
export type IFieldFormattedConsumptionObligation = IFieldFormattedUserDetails & {
	consumptionObligation: number;
};

type IFilterKey = "cpf" | "phone" | "name";

const t = i18n.t;

export class ClientStore {
	@observable
	public userMinimumConsumptionReport: ReportModel<
		MinimumConsumptionReport[],
		{ eventId: string }
	> = new ReportModel(args => enterprise.getMinimumConsumptionReport(args));
	@observable
	public userBonusReport: ReportModel<
		UserBonusReport[],
		{ eventId: string }
	> = new ReportModel(args => enterprise.getBonusReport(args));
	@observable
	public clientsAtEvent: ReportModel<
		EventUser[],
		{ eventId: string }
	> = new ReportModel(args => enterprise.getAllUsersAtEvent(args));

	@observable
	public clientsAtEventReport: ReportModel<
		Client[],
		{ eventId: string; options: OptionsClientsEvent }
	> = new ReportModel(args => enterprise.getClientsAtEvent(args));

	@observable
	public clientDetailsReport: ReportModel<
		ClientDetails,
		{ clientId: string }
	> = new ReportModel(args => enterprise.getClientDetails(args));
	@observable
	public transactionsAtEvent: ReportModel<
		UserTransaction[],
		IClientTransactionData
	> = new ReportModel(({ eventId, clientId }) =>
		enterprise.clientTransactionsAtEvent({ eventId, userId: clientId }),
	);

	@observable
	public clientTransactionReport: ReportModel<
		UserTransaction[],
		IClientTransactionData
	> = new ReportModel(({ clientId, eventId }) =>
		enterprise.clientTransactionsAtEvent({ userId: clientId, eventId }),
	);
	@observable
	public clients: ClientModel[] = [];
	@observable
	public editClientDetailsPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public editUserDetailsPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public getClientsPromisse: IPromiseBasedObservable<void> | null = null;
	@observable
	public getClientPromise: IPromiseBasedObservable<User> | null = null;
	public rootStore: RootStore;

	@observable
	public sincePlaceFilter: Date = datetime().compose(startOf("day"), subtract(7, "day"));

	@observable
	public untilPlaceFilter: Date = datetime().endOf("day");

	@observable
	public clientPastBills?: UserDetailsByEvents[] = undefined;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	public clean() {
		this.clients = [];
		this.editClientDetailsPromise = null;
		this.getClientsPromisse = null;
	}

	public updateNoteFromServer = (incomeClient: ExtendedClient) => {
		let client = this.clients.find(t => t.id === incomeClient.id);
		if (!client) {
			client = new ClientModel(this, incomeClient);
			this.clients.push(client);
		} else {
			client.sync(incomeClient);
		}

		return client;
	};

	public resolve = (incomeClient: ExtendedClient) =>
		this.updateNoteFromServer(incomeClient);

	public getClient = (parameter: "cpf" | "phone" | "zigcode") => async (
		value: string,
	) => {
		this.getClientPromise = fromPromise(
			enterprise
				.getUserAtEvent({
					eventId: this.rootStore.eventStore!.event!.id!,
					filter: { type: parameter, filter: value },
				})
				.catch(errorTap((err: Error) => showErrorNotification(err.message))),
		);

		return this.resolve(await this.getClientPromise!);
	};

	public getClientEventDetails = async (userId: string) => {
		const userDetails = await enterprise
			.getUserDetailsAtEvent({
				eventId: this.rootStore.eventStore!.event!.id,
				userId,
			})
			.catch(errorTap(console.error));
		return userDetails;
	};

	public getClientAtPlace = async (userId: string) => {
		const placeClients = await enterprise
			.clientAtPlace({
				userId,
				placeId: this.rootStore!.placeStore!.place!.id!,
			})
			.catch(errorTap(console.error));
		return placeClients;
	};

	public getEventUserAtEvent = async (userId: string) => {
		const eventUser = await enterprise
			.getEventUserAtEvent({
				userId,
				eventId: this.rootStore.eventStore!.event!.id,
			})
			.catch(errorTap(console.error));
		return eventUser;
	};

	public getClientPlaceDetails = async (
		userId: string,
		since: Date,
		until: Date,
		placeId: string,
	) => {
		const userDetails = await enterprise
			.getUserDetailsAtPlace({
				placeId,
				userId,
				since,
				until,
			})
			.catch(errorTap(console.error));
		return userDetails;
	};

	public getNotEmittedTransactionsFromEvent = async (userId: string) => {
		return await enterprise.getNotEmmitedTransactionsFromEvent({
			eventId: this.rootStore.eventStore!.event!.id,
			userId,
		});
	};

	public getNotEmmitedConsumptionObligationsFromEvent = async (userId: string) => {
		return await enterprise.getNotEmmitedConsumptionObligationsFromEvent({
			eventId: this.rootStore.eventStore!.event!.id,
			userId,
		});
	};

	public getFieldFormattedComsumptionObligationsFromEvent = async (
		userDetails: IFieldFormattedUserDetails,
	) => {
		const notEmittedConsumptionObligations = await this.getNotEmmitedConsumptionObligationsFromEvent(
			userDetails.userId,
		);

		return {
			consumptionObligation: notEmittedConsumptionObligations,
			...userDetails,
		};
	};

	public getFieldFormattedTransactionsFromEvent = async (
		userDetails: IFieldFormattedUserDetails,
	) => {
		const notEmittedTransactions = await this.getNotEmittedTransactionsFromEvent(
			userDetails.userId,
		);

		const data: IFieldFormattedTransaction[] = notEmittedTransactions.map(t => ({
			...t,
			...userDetails,
		}));

		return data;
	};

	public getManyClientsEventDetails = async (usersIds: string[]) => {
		const usersDetails: UserDetails[] = [];
		for (const id of usersIds) {
			usersDetails.push(await this.getClientEventDetails(id));
		}
		return usersDetails;
	};

	public getClientByCpf = this.getClient("cpf");
	public getClientByPhone = this.getClient("phone");
	public getClientByZigCode = this.getClient("zigcode");

	@action
	public clientFilterFactory = (key: IFilterKey) => (value: string) =>
		this.clients.filter(c => {
			if (key === "cpf") return c.cpf === value;
			if (key === "phone") return c.phone === value;
			if (key === "name")
				return (
					treatStringForSearch(c.name).startsWith(treatStringForSearch(value)) ||
					treatStringForSearch(c.name).endsWith(treatStringForSearch(value))
				);
		});

	public getClients(
		placeId = this.rootStore!.placeStore!.place!.id!,
		queries: IGetClientQuery[] = [],
	) {
		this.getClientsPromisse = fromPromise(
			Promise.all(
				queries.map(query =>
					enterprise
						.getUser({ placeId, cpf: query.cpf || "", phone: query.phone || "" })
						.catch(() => undefined)
						.then(user => ({ ...query, user })),
				),
			).then(clientsRes => {
				clientsRes.forEach(c => {
					const { phone, user } = c;
					this.resolve({ phone, ...user! });
				});
			}),
		);
		return this.getClientsPromisse;
	}

	@observable
	public selectedClientsForPromotion: ClientDetails[] = [];
	@observable
	public originalElectedClientsForPromotion: ClientDetails[] = [];

	@action
	public addClientsToPromotion = (clients: any) => {
		this.selectedClientsForPromotion = [
			...this.selectedClientsForPromotion,
			...clients.map((c: any) => ({
				cpf: c.cpf || "",
				phone: c.phone || "",
			})),
		];
	};

	@action
	public cleanSelectedClientsForPromotion = () => {
		this.selectedClientsForPromotion = [];
	};

	@action
	public cleanOriginalClientsForPromotion = () => {
		this.originalElectedClientsForPromotion = [];
	};

	@action
	public removeOneClientForPromotion = (userId: string) => {
		this.selectedClientsForPromotion = this.selectedClientsForPromotion.filter(
			u => u.id !== userId,
		);
	};

	@action
	public getPromotionsUsers = async (promotionId: string) => {
		const userToClient = (user: PromotionUser): ClientDetails => ({
			id: user.id,
			cpf: user.cpf,
			email: null,
			name: user.name,
			zigCode: null,
			avatar: null,
			hasApp: false,
			phone: "",
			hasBiometry: false,
		});

		try {
			this.cleanOriginalClientsForPromotion();
			let users = await enterprise.getPromotionUsers({ promotionId });
			users = uniqBy(users, u => u.id);
			this.originalElectedClientsForPromotion = users.map(userToClient);
			this.selectedClientsForPromotion = users.map<ClientDetails>(userToClient);
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public getClientByCPFOrPhone = async (query: string) => {
		try {
			const user = await enterprise.getUser({
				placeId: this.rootStore!.placeStore!.place!.id!,
				phone: query,
				cpf: query,
			});
			const detailUser = await enterprise.getClientDetails({ clientId: user.id });

			const clientAlreadyAdded =
				this.selectedClientsForPromotion.filter(
					client => client.cpf === detailUser.cpf || client.phone === detailUser.phone,
				).length > 0;
			if (clientAlreadyAdded) {
				showErrorNotification(t("store:clientStore.clientAlreadyAdded"));
			} else {
				this.selectedClientsForPromotion = [
					...this.selectedClientsForPromotion,
					detailUser,
				];
				showSuccessNotification(t("store:clientStore.getClientByCPFOrPhone"));
			}
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public editClientDetails = async (clientId: string, name: string, phone: string) => {
		this.editClientDetailsPromise = fromPromise(
			enterprise
				.editClient({ clientId, name, phone })
				.then(() => {
					ReactGA.event({
						category: "User",
						action: "edit client details",
					});

					showSuccessNotification(t("store:clientStore.editClientDetails"));
					this.fetchClientsAtEventReport(this.rootStore.eventStore!.event!.id!, false);
				})
				.catch(errorTap((err: Error) => showErrorNotification(err.message))),
		);

		await this.editClientDetailsPromise;
	};

	@action
	public editClientDetailsPlace = async (
		clientId: string,
		name: string,
		phone: string,
	) => {
		this.editClientDetailsPromise = fromPromise(
			enterprise
				.editClient({ clientId, name, phone })
				.then(() => {
					ReactGA.event({
						category: "User",
						action: "edit client details",
					});

					showSuccessNotification(t("store:clientStore.editClientDetailsPlace"));
					this.fetchClientsAtEventReport(this.rootStore.placeStore!.place!.id!, false);
				})
				.catch(errorTap((err: Error) => showErrorNotification(err.message))),
		);

		await this.editClientDetailsPromise;
	};

	@action
	public editUserDetails = async (
		userId: string,
		name: string,
		phone: string,
		email: string | null,
		birthDate: Date | null,
	) => {
		this.editUserDetailsPromise = fromPromise(
			enterprise
				.editUserDetails({ user: { phone, name, birthDate, email }, userId })
				.then(() => {
					ReactGA.event({
						category: "User",
						action: "edit user details",
					});

					showSuccessNotification(t("store:clientStore.editUserDetails"));
					this.fetchClientDetailsReport(this.rootStore.placeStore!.place!.id!);
				})
				.catch(errorTap((err: Error) => showErrorNotification(err.message))),
		);

		await this.editUserDetailsPromise;
	};

	public payBills = new fetchModel<PayBillsArgs, MultipleUserIdsResult>({
		fnPromise: args =>
			enterprise.payBillsAtEventWithMultiplePayments({
				userIds: args.users,
				eventId: args.eventId,
				//@refactor-error  Type 'PaymentMethod' is not assignable to type '"Voucher"'
				method: args.paymentMethod,
			}),
		onError: err => showErrorNotification(err.message),
		onSuccess: v => {
			if (v.error.length === 0)
				showMessageOnTop({
					description: t("store:clientStore.payBillsSuccess"),
					type: "success",
					time: 10000,
				});
			else
				showMessageOnTop({
					description: t("store:clientStore.payBillsWarning", {
						success: v.success.length,
						errors: v.error.length,
					}),
					type: "warning",
					time: 20000,
				});
		},
		onFetching: () =>
			showMessageOnTop({
				description: t("store:clientStore.payBillsLoading"),
				time: 3000,
				type: "loading",
			}),
	});

	public payBillAtPlace = async (
		userId: string,
		placeId: string,
		payments: BillPayment[],
		since: Date,
		until: Date,
		reason: string | null,
	) => {
		try {
			await enterprise.payBillAtPlaceInPeriodWithMultiplePayments({
				userId,
				placeId,
				payments,
				since,
				until,
				reason,
			});
			showSuccessNotification(t("store:clientStore.payBillAtPlace"));
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
				console.error(err.message);
			}
		}
	};

	public payAllBillsAtPlace = async (
		userIds: string[],
		placeId: string,
		since: Date,
		until: Date,
		method: EnterprisePaymentMethod,
	) => {
		try {
			await enterprise.payBillsAtPlaceInPeriodWithMultiplePayments({
				userIds,
				placeId,
				since,
				until,
				method,
			});
			showSuccessNotification(t("store:clientStore.payAllBillsAtPlace"));
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public fetchUserPastBills = async (
		placeId: string,
		userId: string,
		since: Date,
		until: Date,
	) => {
		this.clientPastBills = undefined;
		try {
			const pastBills = await enterprise.getUserDetailsPastBills({
				placeId,
				userId,
				since,
				until,
			});
			this.clientPastBills = pastBills;
			return pastBills;
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	public userConsolidatedAtPlace = new fetchModel<
		{
			placeId: string;
			userId: string;
			since: Date;
			until: Date;
		},
		ConsolidatedHistory[]
	>({
		fnPromise: args => enterprise.getUserConsolidatedHistoryInPlace(args),
		onError: err => showErrorNotification(err.message),
	});

	public userConsolidatedAtEvent = new fetchModel<
		{
			eventId: string;
			userId: string;
		},
		ConsolidatedHistory
	>({
		fnPromise: args => enterprise.getUserConsolidatedHistoryInEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	public userCompleteHistoryAtPlace = new fetchModel<
		{
			placeId: string;
			userId: string;
			since: Date;
			until: Date;
		},
		UserEventHistory[]
	>({
		fnPromise: args => enterprise.getUserCompleteHistoryInPlace(args),
		onError: err => showErrorNotification(err.message),
	});

	public userCompleteHistoryAtEvent = new fetchModel<
		{
			eventId: string;
			userId: string;
		},
		UserEventHistory
	>({
		fnPromise: args => enterprise.getUserCompleteHistoryInEvent(args),
		onError: err => {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
			console.error(err);
		},
	});

	public getUserTransaction = new fetchModel<
		{
			transactionId: string;
			userId: string;
		},
		UserTransaction
	>({
		fnPromise: args =>
			enterprise.getUserTransaction({ id: args.transactionId, userId: args.userId }),
		onError: err => showErrorNotification(err.message),
	});

	public getClientTransactionTransferProducts = new fetchModel<
		{
			transactionId: string;
		},
		ClientTransferTransactionDetails[]
	>({
		fnPromise: args => enterprise.getClientTransactionTransferProducts(args),
		onError: err => showErrorNotification(err.message),
	});

	public getUserPostPayment = new fetchModel<
		{
			transactionId: string;
		},
		PostPaymentWithPastBill
	>({
		fnPromise: args => enterprise.getUserPostPayment({ id: args.transactionId }),
		onError: err => showErrorNotification(err.message),
	});

	public getUserRefundTransaction = new fetchModel<
		{
			transactionId: string;
		},
		UserRefundTransaction
	>({
		fnPromise: args => enterprise.getUserRefundTransaction({ id: args.transactionId }),
		onError: err => showErrorNotification(err.message),
	});

	public getOpenedBillsForClient = new fetchModel<
		{
			userId: string;
			placeId: string;
			since: Date;
			until: Date;
		},
		ClientOpenedBill[]
	>({
		fnPromise: args =>
			enterprise.getOpenedBillsForClient({
				userId: args.userId,
				placeId: args.placeId,
				since: args.since,
				until: args.until,
			}),
		onError: err => showErrorNotification(err.message),
	});

	@action
	public fetchClientsAtEventReport = async (eventId: string, onlyNotIssued: boolean) =>
		await this.clientsAtEventReport.fetch({
			args: { eventId, options: { invoicesNotIssued: onlyNotIssued || null } },
		});

	@action
	public fetchClientsAtEvent = async (eventId: string) =>
		await this.clientsAtEvent.fetch({ args: { eventId } });

	@action
	public fetchClientDetailsReport = (clientId: string) =>
		this.clientDetailsReport.fetch({ args: { clientId } });

	@action
	public fetchUserBonusReport = (eventId: string) =>
		this.userBonusReport.fetch({ args: { eventId } });

	@action
	public fetchUserMinimumConsumptionReport = (eventId: string) =>
		this.userMinimumConsumptionReport.fetch({ args: { eventId } });

	@action
	public fetchClientTransactionsReport = (clientId: string, eventId: string) =>
		this.clientTransactionReport.fetch({ args: { clientId, eventId } });

	public getDiscountForClient = new fetchModel<
		{ userId: string; placeId: string; since: Date; until: Date },
		ClientDiscount[]
	>({
		fnPromise: args => enterprise.getDiscountForClient(args),
		onError: err => showErrorNotification(err.message),
	});

	public getBonusForClient = new fetchModel<
		{ userId: string; placeId: string; since: Date; until: Date },
		ClientBonus[]
	>({
		fnPromise: args => enterprise.getBonusForClient(args),
		onError: err => showErrorNotification(err.message),
	});

	public getConsumptionObligationReportAtEvent = new fetchModel<
		{ eventId: string },
		ConsumptionObligationReport[]
	>({
		fnPromise: args => enterprise.getConsumptionObligationReportAtEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	public getConsumptionObligationReportAtPlace = new fetchModel<
		{ placeId: string; since: Date; until: Date },
		ConsumptionObligationReport[]
	>({
		fnPromise: args =>
			enterprise.getConsumptionObligationReportAtPlace({
				...args,
				since: args.since,
				until: args.until,
			}),
		onError: err => showErrorNotification(err.message),
	});

	public payBillAtEventWithMultiplePayments = new fetchModel<
		{ userId: string; eventId: string; payments: BillPayment[]; reason?: string | null },
		PixCharge | null
	>({
		fnPromise: args => enterprise.payBillAtEventWithMultiplePayments(args),
		onSuccess: () =>
			showSuccessNotification(t("store:eventStore.payBillAtEventWithMultiplePayments")),
		onError: err => showErrorNotification(err.message),
	});

	public payBillWithPix = new fetchModel<
		{
			userId: string;
			eventId: string;
			payments: BillPayment[];
			reason: string | null;
		},
		PixCharge | null
	>({
		fnPromise: args => enterprise.payBillAtEventWithMultiplePayments(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () => showSuccessNotification(t("store:clientStore.createPixCharge")),
	});

	public sendPixEmailToPayBill = new fetchModel<
		{ pixChargeId: string; userEmail: string },
		void
	>({
		fnPromise: args => enterprise.sendPixEmailToPayBill(args),
		onError: err => showErrorNotification(err.message),
	});

	public refundAppSell = async (
		transactionId: string,
		reason: string,
		shouldReturnToStorage: boolean,
		refundType: "cancel" | "refund",
	): Promise<boolean> => {
		try {
			await enterprise.refundAppSell({ transactionId, reason, shouldReturnToStorage });
			if (refundType === "refund") {
				showSuccessNotification(t("store:clientStore.refundAppSell"));
			} else {
				showSuccessNotification(t("store:clientStore.cancelAppSell"));
			}
			return true;
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
			return false;
		}
	};
}
