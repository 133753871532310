import { ProductsSoldReportResponse } from "#resources/api/enterprise-generated";
import { useState } from "react";
import { useDisclosure } from "#resources/hooks";

export const useMountableProductsModal = () => {
	const [
		selectedProduct,
		setSelectedProduct,
	] = useState<ProductsSoldReportResponse | null>(null);

	const { isOpen, onClose, onOpen } = useDisclosure({
		onCloseCallback: () => {
			setSelectedProduct(null);
		},
	});

	const handleOpenMountableProductsModal = (product: ProductsSoldReportResponse) => {
		setSelectedProduct(product);
		onOpen();
	};

	const products = selectedProduct?.mountableProducts || [];
	const productName = selectedProduct?.productName ?? "";

	return {
		handleOpenMountableProductsModal,
		open: isOpen,
		onClose,
		products,
		productName,
	};
};
