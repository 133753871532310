import {
	ModalOnboarding,
	ModalOnboardingProps,
} from "#components/modal-onboarding/modal-onboarding";
import React from "react";
import { useTranslation } from "react-i18next";

import bannerImg from "./banner.png";

type SalesReportModalProps = Pick<ModalOnboardingProps, "onClose">;

export const SalesReportModal = ({ onClose }: SalesReportModalProps) => {
	const { t } = useTranslation("components", {
		keyPrefix: "notifications.onboarding.salesReport",
	});

	return (
		<ModalOnboarding
			bannerSrc={bannerImg}
			title={t("title")}
			content={t("content")}
			onClose={onClose}
			width={756}
			actions={{
				primary: {
					label: t("primaryActionLabel"),
					buttonProps: {
						trailingIcon: null,
					},
				},
			}}
		/>
	);
};
