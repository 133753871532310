import { useStores } from "#stores";

export const useRollout = ({
	allowedEmails,
	allowedOrgIds,
	validationType,
}: {
	allowedEmails: string[];
	allowedOrgIds?: string[];
	validationType?: "OR" | "AND";
}) => {
	const { authStore, organizationStore } = useStores();

	const isAllowedUser =
		authStore.currentEmployee?.username &&
		allowedEmails.some(email => authStore.currentEmployee?.username.includes(email));

	if (!allowedOrgIds) {
		return Boolean(isAllowedUser);
	}

	const isAllowedOrg = allowedOrgIds?.some(
		orgId => orgId === organizationStore.organization?.id,
	);

	if (validationType === "AND") {
		return Boolean(isAllowedUser && isAllowedOrg);
	}

	return Boolean(isAllowedUser || isAllowedOrg);
};
