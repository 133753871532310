import { merge } from "lodash-es";
import es_MXJson from "./es-MX.json";
import { PURCHASE_INVOICE_ES_MX } from "#pages/place/purchase-invoice/translations/purchase-invoice-es-mx";
import { IMPORT_FR_FR } from "#pages/place/purchase-invoice/import-invoice/translations/import-fr-fr";
import { CONCILIATION_ES_MX } from "#pages/backoffice/conciliation/translations/conciliation-es-mx";
import { deliverySettings } from "./place/delivery/settings";

const es_MX = merge(es_MXJson, {
	place: {
		backoffice: { conciliation: CONCILIATION_ES_MX },
		purchaseInvoiceV2: PURCHASE_INVOICE_ES_MX,
		import: IMPORT_FR_FR,
		delivery: {
			updateManyExternalIntegrations: {
				success: "Integraciones actualizadas conxito",
			},
			deliverySettings,
		},
	},
	backoffice: { conciliation: CONCILIATION_ES_MX },
});

export { es_MX };
