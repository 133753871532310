export const adjustments = {
	title: "Ajustes",
	filename: "ajustes",
	noDataToShow: "Não há nada a mostrar para o período selecionado",
	label: {
		pos: "Faturamento em Máquinas Não Integradas (POS)",
		posTax: "Taxa de Adiquirência sobre Máquinas Não Integradas (POS)",
		operatorFee: "Diária de Operador",
		systemSetup: "Setup de Sistema",
		wifiSetup: "Setup de Wifi",
		equipmentsPurchase: "Compra de Equipamentos",
		equipmentsRental: "Aluguel de Equipamentos",
		zigDevicePurchase: "Compra de Comandas",
		equipmentsMaintenance: "Manutenção de Equipamentos",
		wifiAssistance: "Assistência Técnica de Wifi",
		zigFee: "Taxa Zig",
		totemFee: "Aluguel Totem",
		cardActivations: "Taxa de Ativação",
		genericOperationsFee: "Despesas Gerais de Operação",
		genericServices: "Outros Serviços e Cobranças",
		bonifications: "Outras Bonificações",
		expiredRecharges: "Recargas Expiradas",
		notUsedRecharges: "Sobra de Recargas ",
		generalZigMachineTPV: "Faturamento via adquirência integrada ZIG",
		generalZigMachineTax: "Taxas de Adquirência sobre Máquinas Integradas",
		anticipationFee: "Taxa de Antecipação",
		payments: "Pagamentos",
		paymentsTax: "Taxa de Pagamento a Fornecedores",
		reimbursements: "Reembolsos a Clientes",
		withdraw: "Saques",
		balanceAdjustment: "Balanceamento de Saldo",
		renegotiation: "Renegociação",
		advance: "Adiantamento",
		advanceFee: "Taxa de Adiantamento",
		balanceRetention: "Retenção de Saldo",
		balanceTransfer: "Transferência Entre Places",
		others: "Outros",
		residueRemain: "Retenção de Resíduo",
		missingCards: "Cartões Extraviados",
		billingInvoice: "Cobrança Nota Fiscal",
		guaranteedMinimumSupplement: "Complemento Mínimo Garantido",
		commandFiscalPurchase: "Compra de Comanda Fiscal",
		accountsReceivableLegal: "CR - Jurídico",
		cardDiscount: "Desconto de Cartões",
		discountGeneralOperatingExpenses: "Desconto Despesas Gerais de Operação",
		discountOperatorDailyRate: "Desconto Diária de Operador",
		generalDiscount: "Desconto Geral",
		dailyCoordinatorRate: "Diárias de Coordenadores",
		dailyHeadsRate: "Diárias de Heads",
		setupDailyCoordinatorRate: "Diárias de Setup Coordenadores",
		setupDailyHeadsRate: "Diárias de Setup Heads",
		setupDailySupervisorsRate: "Diárias de Setup Supervisores",
		setupDailyTechnicalRate: "Diárias de Setup Técnico",
		setupDailyTraineeRate: "Diárias de Setup Trainee",
		dailySupervisorRate: "Diárias de Supervisores",
		dailyTechnicianRate: "Diárias de Técnico",
		dailyTraineeRate: "Diárias de Trainee",
		damagedEquipment: "Equipamento Avariados",
		integratedAPPBilling: "Faturamento App Integrado",
		apartCreditBilling: "Faturamento Crédito Apartado",
		integratedCreditBilling: "Faturamento Crédito Integrado",
		apartDebitBilling: "Faturamento Débito Apartado",
		integratedDebitBilling: "Faturamento Débito Integrado",
		apartBillingPIX: "Faturamento PIX Apartado",
		integratedPIXBilling: "Faturamento PIX Integrado",
		integratedAdvancedRechargeBilling: "Faturamento Recarga Antecipado Integrado",
		interestandPenalties: "Juros e Multa",
		softwareLicense: "Licença de Software",
		replacedMachines: "Máquinas Substituidas",
		minimumGuaranteedpermachine: "Mínimo Garantido por máquina",
		otherEquipment: "Outros Equipamentos",
		rebate: "Rebate",
		setupAdvancedRecharge: "Setup Recarga Antecipada",
		setupRefundSite: "Setup Site de Reembolso",
		acquirementfeeforIntegratedZIGApplication:
			"Taxa de adquirência sobre Aplicativo ZIG Integrado",
		acquiringfeeonapartCredit: "Taxa de adquirência sobre Crédito Apartado",
		acquiringfeeforIntegratedCredit: "Taxa de adquirência sobre Crédito Integrado",
		acquiringfeeforapartDebit: "Taxa de adquirência sobre Débito Apartado",
		acquiringfeeonIntegratedDebit: "Taxa de adquirência sobre Débito Integrado",
		acquiringfeeforapartPIX: "Taxa de adquirência sobre PIX Apartado",
		acquiringfeeforIntegratedPIX: "Taxa de adquirência sobre PIX Integrado",
		acquiringfeeforAdvancedRecharge: "Taxa de adquirência sobre Recarga Antecipada",
		refundfee: "Taxa de Reembolso",
		relatedpartyretentionfee: "Taxa de retenção de partes relacionadas",
		rappiFee: "Taxa Rappi",
		SMSFee: "Taxa SMS",
		residualFee: "Taxa sobre Resíduo",
		cardtoCashExchange: "Troca de Cartões por Dinheiro",
		cardtoProductExchange: "Troca de Cartões por Produto",
		travelFood: "Alimentação de Viagem",
		shipping: "Frete",
		accommodation: "Hospedagem",
		transport: "Transporte",
		pixRefundFee: "Taxa Reembolso PIX",
		softwareLicenseMachine: "Licença de Software Máquina",
		softwareLicenseTotem: "Licença de Software Totem",
		integratedAdvanceDebitBilling: "Faturamento Débito Antecipado Integrado",
		integratedAdvanceCreditBilling: "Faturamento Crédito Antecipado Integrado",
		integratedAdvancePixBilling: "Faturamento Pix Antecipado Integrado",
		integratedAdvanceDebitFee: "Taxa Débito Antecipada Integrado",
		integratedAdvanceCreditFee: "Taxa Crédito Antecipada Integrado",
		integratedAdvancePixFee: "Taxa Pix Antecipada Integrado",
		zeDeliveryDiscount: "Desconto Zé Delivery",
		ticketPrinting: "Impressão de ingressos",
		validatorRental: "Licença de Validadores",
		zeDeliveryRetention: "Retenção Zé Delivery",
		valueAddedTax: "IVA",
		moneyTPV: "TPV Dinheiro",
		acquiringFeeForIntegratedInstallmentCredit:
			"Taxa de adquirência sobre Crédito Parcelado Integrado",
		issuedCharge: "Cobrança Emitida",
		softwareLicenseGiftback: "Licença de Software Giftback",
		loanBracelet: "Empréstimo de pulseira",
		lendingCards: "Empréstimo de cartão",
		externalResourceContribution: "Aporte de recursos externos",
		generalZigMachineCreditIn2Installments: "TPV Credito parcelado 2X",
		generalZigMachineCreditIn3Installments: "TPV Credito parcelado 3X",
		generalZigMachineCreditIn4Installments: "TPV Credito parcelado 4X",
		generalZigMachineCreditIn5Installments: "TPV Credito parcelado 5X",
		generalZigMachineCreditIn6Installments: "TPV Credito parcelado 6X",
		generalZigMachineCreditIn7Installments: "TPV Credito parcelado 7X",
		generalZigMachineCreditIn8Installments: "TPV Credito parcelado 8X",
		generalZigMachineCreditIn9Installments: "TPV Credito parcelado 9X",
		generalZigMachineCreditIn10Installments: "TPV Credito parcelado 10X",
		generalZigMachineCreditIn11Installments: "TPV Credito parcelado 11X",
		generalZigMachineCreditIn12Installments: "TPV Credito parcelado 12X",
		generalZigMachineCreditIn2InstallmentsFEE: "Taxa Credito Parcelado 2X",
		generalZigMachineCreditIn3InstallmentsFEE: "Taxa Credito Parcelado 3X",
		generalZigMachineCreditIn4InstallmentsFEE: "Taxa Credito Parcelado 4X",
		generalZigMachineCreditIn5InstallmentsFEE: "Taxa Credito Parcelado 5X",
		generalZigMachineCreditIn6InstallmentsFEE: "Taxa Credito Parcelado 6X",
		generalZigMachineCreditIn7InstallmentsFEE: "Taxa Credito Parcelado 7X",
		generalZigMachineCreditIn8InstallmentsFEE: "Taxa Credito Parcelado 8X",
		generalZigMachineCreditIn9InstallmentsFEE: "Taxa Credito Parcelado 9X",
		generalZigMachineCreditIn10InstallmentsFEE: "Taxa Credito Parcelado 10X",
		generalZigMachineCreditIn11InstallmentsFEE: "Taxa Credito Parcelado 11X",
		generalZigMachineCreditIn12InstallmentsFEE: "Taxa Credito Parcelado 12X",
		totalTpvBarIncome: "Taxa Recebimento Bar",
		totalTpvEntranceIncome: "Taxa Recebimento Bilheteria",
		totalTpvEntranceIncomeConsumption: "Taxa Recebimento Consumo Bilheteria",
		totalTpvBarMinimumConsumption: "Taxa Minima Recebimento Bar",
		totalTpvBonus: "Taxa Recebimento Bonus",
		advanceRenegotiation: "Renegociação de Advance",
		zigTicketsRetention: "Retenção Zig Tickets",
		onlineDeliveryAcquisitionFee: "Taxa de Adquirência sobre Delivery Online",
	},
	table: {
		date: "Data de competência",
		payDate: "Data de liquidação",
		category: "Categoria",
		obs: "Observação",
		noObs: "Sem observação",
		totalToReceive: "A receber",
		totalToPay: "A pagar",
		balance: "Saldo",
		actions: "Ações",
		total: "Total",
	},
};
