import { PurchaseInvoiceTranslations } from "./purchase-invoice-pt-br";

export const PURCHASE_INVOICE_ES_MX: PurchaseInvoiceTranslations = {
	importationInvoices: {
		cards: {
			importXml: {
				title: "Importación de facturas",
				description: "Importar sus facturas fiscales de forma manual",
				button: "Haga clic para importar",
			},
			importManual: {
				title: "Entrada manual",
				description: "Introduzca sus facturas fiscales manualmente",
				button: "Haga clic para importar",
			},
			importSefaz: {
				title: "Importación de facturas",
				description: "Importar sus facturas fiscales directamente del Sefaz",
				button: "Haga clic para importar",
			},
		},
		importXml: {
			input: {
				title: "Elija el fichero XML para iniciar la importación",
				description: "Puedes hacer clic en « Seleccionar Archivo » o arrastre un fichero",
				button: "Elegir Archivo",
			},
			labels: {
				storage: "Almacén",
				selectStorage: "Seleccione un almacén",
				xmlInvoice: "XML de la factura",
				changeXml: "Cambiar XML",
				back: "Volver",
				import: "Importar",
			},
			selectedFile: "El archivo seleccionado es: ",
			xmlRequiredMessage: "El archivo XML es obligatorio.",
			storageIdRequiredMessage: "Seleccione un almacén.",
			uploadErrorMessage: "No se pudo leer el archivo.",
			modalTitle: "Importación de XML",
		},
		importManual: {
			modalTitle: "Entrada manual",
		},
		fiscalProfileModalTitle: "Importación via Sefaz",
		fiscalProfileRequiredMessage: "El perfil fiscal es obligatorio.",
		labels: {
			fiscalProfile: "Perfil Fiscal",
			selectFiscalProfile: "Seleccione un perfil fiscal",
			back: "Volver",
			continue: "Continuar",
		},
		importSefazInvoice: {
			title: "Importación via Sefaz",
		},
	},
};
