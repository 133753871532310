export const pixTypeInput = {
	pixKeyLabel: "PIX Key",
	pixTypeLabel: "PIX Key Type",
	pixTypePlaceholder: "Select the PIX Key Type",
	pixKeyType: {
		random: "Random",
		phone: "Phone",
		copyPaste: "Copy and Paste",
	},
	validation: {
		invalidPixKey: "Invalid PIX key",
	},
};
